<div class="container-fluid">
    <div class="row mt-1">
        <app-breadcrumb [breadcrumbitems]="breadcrumbItems"></app-breadcrumb>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="accordion-wrapper ">
                <div class="accordion mb-3" id="homeAccordianOne">
                  <div class="card">
                    <div class="card-header">
                      <div class="d-flex flex-row justify-content-between">
                        <span class="commonFont pt-2">GENERAL</span>
                        <button class="btn headButton" type="button" data-bs-toggle="collapse" id="accordion3Btn"
                          accordianActive="true" data-bs-target="#collapsesearchfilter1" aria-expanded="true"
                          aria-controls="collapsesearchfilter1" (click)="isOpen1=!isOpen1">
              
                          <img class="clpseBtn collapsesearchfilterBtn" [ngClass]="{'active':isOpen1}" alt="img">
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="collapsesearchfilter1" class="collapse show" aria-labelledby="headingOne" data-bs-parent="#proposalHist">
                  <div class="cardBody">
                    <div class="accordion-main p-2">
                        <div class="d-flex mt-3">
                            <div class="col-md-2"><span class="label-tmna ms-4">Description</span></div>
                            <div class="col-md-6"> <input class="input-tmna" type="text" placeholder="Enter Description"></div>
                        </div>
                        <div class="d-flex mt-3">
                            <div class="col-md-2"><span class="label-tmna ms-4">Purchasing Group</span></div>
                            <div class="col-md-6"> <input class="input-tmna" type="text" placeholder="Enter Purchasing Group"></div>
                        </div>
                        <div class="d-flex mt-3">
                            <div class="col-md-2"><span class="label-tmna ms-4">Lead Buyer Email</span></div>
                            <div class="col-md-6"><span class="label-tmna"> {{leadBuyerEmail}}</span></div>
                        </div>
                        <div class="d-flex mt-3">
                            <div class="col-md-2">
                                <span class="label-tmna ms-4">Supplier Contacts</span>
                            </div>
                            <div class="col-md-8">
                              <ag-grid-angular class="ag-theme-balham " [rowData]="rowDataSupplierContacts" [columnDefs]="columnDefSupplierContacts"
                              (gridReady)="onGridReady($event)" [pagination]="true" headerHeight="50" [defaultColDef]="defaultColDef"
                              [paginationPageSize]="paginationPageSize" [domLayout]="domLayout">
                            
                              </ag-grid-angular>
                            </div>

                        </div>
                        <div class="d-flex mt-3" style="margin-bottom: 30px;">
                          <div class="col-md-2">
                              <span class="label-tmna ms-4">Suppliers</span>
                          </div>
                          <div class="col-md-8">
                            <ag-grid-angular class="ag-theme-balham " [rowData]="rowDataSuppliers" [columnDefs]="columnDefSuppliers"
                            (gridReady)="onGridReady($event)" [pagination]="true" headerHeight="50" [defaultColDef]="defaultColDef"
                            [paginationPageSize]="paginationPageSize" [domLayout]="domLayout">
                          
                            </ag-grid-angular>
                          </div>

                      </div>
                      
                    </div>
              
                  </div>
                </div>
              </div>
              <div class="accordion-wrapper mt-4">
                <div class="accordion mb-3" id="homeAccordianOne">
                  <div class="card">
                    <div class="card-header">
                      <div class="d-flex flex-row justify-content-between">
                        <span class="commonFont pt-2">INDIVIDUAL EXPECTED TASK</span>
                        <div class="d-flex flex-row ">
                          <button class="btn headButton" type="button" class="closeButton" style="width: fit-content; padding: 0 10px 0 10px;">
                            <img src="\assets\images\history-icon.png" height="20" width="20" alt="Show Versioning History">
                            SHOW VERSIONING HISTORY
                          </button>
                          <button class="btn headButton" type="button" data-bs-toggle="collapse" id="accordion3Btn"
                            accordianActive="true" data-bs-target="#collapsesearchfilter2" aria-expanded="true"
                            aria-controls="collapsesearchfilter2" (click)="isOpen2=!isOpen2">
                
                            <img class="clpseBtn collapsesearchfilterBtn" [ngClass]="{'active':isOpen2}" alt="img">
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="collapsesearchfilter2" class="collapse show" aria-labelledby="headingOne"    data-bs-parent="#proposalHist">
                  <div class="cardBody">
                    <div class="accordion-main p-2 mb-5">
                        <div class="p-2">
                          <ag-grid-angular class="ag-theme-balham " [rowData]="rowDataIndividualExpectedTasks" [columnDefs]="columnDefIndividualExpectedTasks"
                          (gridReady)="onGridReady($event)" [pagination]="true" headerHeight="50" [defaultColDef]="defaultColDef"
                          [paginationPageSize]="paginationPageSize" [domLayout]="domLayout">
                        
                          </ag-grid-angular>
                        </div>
                    </div>
                  </div>
                </div>
              </div> 
        </div>
    </div>
</div>
