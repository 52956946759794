import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LookupService {
  apiBaseUrl = 'http://localhost:3000';
  apiVersion = '/api/v1';
  serviceUrl = this.apiBaseUrl;
  accessToken: any;
  contentType =  'application/json'
  apiUrl = 'https://dlrqja4fwj.execute-api.us-west-2.amazonaws.com';
  environment: any;

  constructor(private readonly http: HttpClient) { }

  public getSupplierList(body): Observable<any> {
    const url = `${this.apiUrl}/dev/lookup/api/v1/supplierList`;
    return this.http.post<any>(url, body);
  }

  public getSftyRiskCommodity(appParentId, fiscalId, empId): Observable<any> {
    const url = `${this.apiUrl}/dev/lookup/api/v1/getSftyRiskCommodity?appParentId=${appParentId}&fiscalId=${fiscalId}&empId=${empId}`;
    return this.http.get<any>(url);
  }

  public getSupplierTierResult(appParentId, fiscalId, empId): Observable<any> {
    const url = `${this.apiUrl}/dev/lookup/api/v1/getSupplierTierResult?appParentId=${appParentId}&fiscalId=${fiscalId}&empId=${empId}`
    return this.http.get<any>(url);
  }

  public getISESuppliers(fiscalYear): Observable<any> {
    const url = `${this.apiUrl}/dev/lookup/api/v1/getISEList?fiscalYear=${fiscalYear}`;
    return this.http.get<any>(url);
  }

  public toggleISESuppliers(body): Observable<any> {
    const url = `${this.apiUrl}/dev/lookup/api/v1/toggleIseSupplier`;
    return this.http.put<any>(url, body);
  }

  public getAssumeList(employeeId): Observable<any> {
    const url = `${this.apiUrl}/dev/lookup/api/v1/getAssumeIdentityList?employeeId=${employeeId}`;
    return this.http.get<any>(url);
  }

  public getAssumeInfo(employeeId): Observable<any> {
    const url = `${this.apiUrl}/dev/lookup/api/v1/getAssumeIdentityInfo?employeeId=${employeeId}`;
    return this.http.get<any>(url);
  }
}
