import { Component, HostListener } from '@angular/core';
import { GridApi, GridOptions } from 'ag-grid-community';

interface FileData {
  name: string;
  isSelected: boolean;
}

@Component({
  selector: 'app-emailer',
  templateUrl: './emailer.component.html',
  styleUrl: './emailer.component.css'
})


export class EmailerComponent {
  files: FileData[] = [];
  isAnyFilesChosen: boolean;
  gridOptions: GridOptions;
  gridApi: GridApi;
  domLayout='autoHeight';

  defaultColDef={ suppressNavigable: true,
    cellClass: 'no-border',
    suppressMovable: true,
    sortable: false,
    unSortIcon: true,
    floatingFilter: true,
    filter: 'agTextColumnFilter',
    wrapText: true,
    minWidth: 130,
   };

  supplierFlagData = ['CPS Suppliers - Early APR Due Date','Parts: Senior Management BRM Supplier','Parts: Manager BRM Supplier','Materials: Senior Management BRM Supplier','Materials: Manager BRM Supplier','Parts: Acknowledgement Only','Materials: Acknowledgement Only'];
  reportPeriodData = ['2024-1 Annual Planning Volume','2023-1 Annual Planning Volume','2022-1 Annual Planning Volume','2021-1 Annual Planning Volume'];
  
  columnDefForEmailer = [
    {
      headerName: 'SUPPLIER',
      field: 'supplier',
      flex: 1
    }, {
      headerName: 'SUPPLIER NAME',
      field: 'supplier_name',
      flex: 1,
    }, {
      headerName: 'EMAIL (RECIPIENT)',
      field: 'email',
      flex: 5,
    }
  ]
  emailValue = 'plkerns@mmm.com, JRFRASER!@MMM.com, Jose.dearmas@toyota.com'
  rowDataForEmailer = [
    {'supplier': '4450, 0450', 'supplier_name': 'CMWA', 'email': this.emailValue},
    {'supplier': '5450, 0451', 'supplier_name': 'CMWA', 'email': this.emailValue},
    {'supplier': '6450, 0452', 'supplier_name': 'CMWA', 'email': this.emailValue},
    {'supplier': '7450, 0453', 'supplier_name': 'CMWA', 'email': this.emailValue},
    {'supplier': '8450, 0454', 'supplier_name': 'CMWA', 'email': this.emailValue},
    {'supplier': '9450, 0455', 'supplier_name': 'CMWA', 'email': this.emailValue},
    {'supplier': '0450, 0456', 'supplier_name': 'CMWA', 'email': this.emailValue},
    {'supplier': '0450, 0457', 'supplier_name': 'CMWA', 'email': this.emailValue},
    {'supplier': '0450, 0458', 'supplier_name': 'CMWA', 'email': this.emailValue},
    {'supplier': '0450, 0459', 'supplier_name': 'CMWA', 'email': this.emailValue},
    {'supplier': '0451, 0450', 'supplier_name': 'CMWA', 'email': this.emailValue},
    {'supplier': '0452, 0450', 'supplier_name': 'CMWA', 'email': this.emailValue},
    {'supplier': '0453, 0450', 'supplier_name': 'CMWA', 'email': this.emailValue},
    {'supplier': '0454, 0450', 'supplier_name': 'CMWA', 'email': this.emailValue},
    {'supplier': '0460, 0450', 'supplier_name': 'CMWA', 'email': this.emailValue},
    {'supplier': '0561, 0450', 'supplier_name': 'CMWA', 'email': this.emailValue},
    {'supplier': '0550, 0450', 'supplier_name': 'CMWA', 'email': this.emailValue},
    {'supplier': '0650, 0450', 'supplier_name': 'CMWA', 'email': this.emailValue},
    {'supplier': '0750, 0450', 'supplier_name': 'CMWA', 'email': this.emailValue},
    {'supplier': '0850, 0450', 'supplier_name': 'CMWA', 'email': this.emailValue},
    {'supplier': '0950, 0450', 'supplier_name': 'CMWA', 'email': this.emailValue},
    {'supplier': '1450, 0450', 'supplier_name': 'CMWA', 'email': this.emailValue},
    {'supplier': '2450, 0450', 'supplier_name': 'CMWA', 'email': this.emailValue},
    {'supplier': '3450, 0450', 'supplier_name': 'CMWA', 'email': this.emailValue},
  ]

  rowSelection = {
    mode: "multiRow",
  }
  gridColumnApi: any;

  onSelection() {
    const selectedRows = this.gridApi.getSelectedRows();
    this.processSelectedData(selectedRows);
  }

  processSelectedData(_selectedRows: any) {
    // console.log('selected Row Data:', selectedRows)
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if(input.files && input.files.length > 0) {
      const file = input.files[0];
      this.files.push({
        name: file.name,
        isSelected: false
      });
      input.value = '';
    }
    this.onCheckboxSelected();
  }

  removeAttachment(index: number): void {
    this.files.splice(index, 1);
    this.onCheckboxSelected();
  }

  onCheckboxSelected(): void {
    this.isAnyFilesChosen = this.files.some((file) => file.isSelected);
  }

  onGridReady(params:any){
    this.gridApi = params?.api;
    this.gridColumnApi = params?.columnApi;
    this.gridApi?.sizeColumnsToFit(); 
  }
  @HostListener("window:resize", ['$event'])
  onResize(_event) {

    if (this.gridApi != null) {

      this.gridApi?.sizeColumnsToFit();
      this.gridApi.refreshCells();
    }

  }
}
