import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './admin/admin.component';
import { HomeComponent } from './home/home.component';
import { PacketSetComponent } from './packet-set/packet-set.component';
import { ReportsComponent } from './reports/reports.component';
import { IseComponent } from './ise/ise.component';
import { IetComponent } from './ise/iet/iet.component';
import { SupplierDetailsComponent } from './supplier-details/supplier-details.component';


const routes: Routes = [
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  {path:'admin',component: AdminComponent},
  {path:'home',component: HomeComponent},
  {path:'packetSet',component: PacketSetComponent},
  {path:'reports',component: ReportsComponent},
  {path:'ise',component:IseComponent},
  {path:'supplierDetails', component: SupplierDetailsComponent},
  {path:'iet', component: IetComponent},
  
  // wild card routing must be always at the end
  { path: '**', redirectTo: 'home' }
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
