import { Component, OnInit, input } from '@angular/core';
import * as $ from 'jquery';
import { Router } from '@angular/router';
import { LookupService } from 'src/app/services/lookup.service';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrl: './navigation-bar.component.css'
})
export class NavigationBarComponent implements OnInit{
  
  title = 'app-ui';
  isCloseClicked=false;
  newClickLink=false;
  selectedAllfunctionComp=false;
  activeLink ='active-link';
  loginUserName='Kumar Satyam';
  loginUserRole='Buyer'
  constructor(private readonly router: Router,private readonly lookup:LookupService
    ) {}
  employeeId:any=0;
  ngOnInit(): void {
    this.getAssumeList();
  }

  getAssumeList(){
    this.lookup.getAssumeList(this.employeeId).subscribe(data=>{
      this.assumeIdentity=data.body.response;
      this.mainAssumeIdentity=data.body.response;
    })
  }
  assumeIdentity:any;
  mainAssumeIdentity:any;
  inputAssume:any;
  assumeSelect:any;
  assumeEmployeeId:any;
  search(inputAssume:any){
    this.assumeSelect='';
    if(inputAssume.length===0){
      this.assumeIdentity=this.mainAssumeIdentity;
    }
    else {
    let dummyAssumeIdentity=[];
    for(let i=0;i<this.mainAssumeIdentity.length;i++){
      if((this.mainAssumeIdentity[i].pdkusrname.toLowerCase()).includes(inputAssume.toLowerCase())){
        dummyAssumeIdentity.push(this.mainAssumeIdentity[i])
      }
    }
    this.assumeIdentity=dummyAssumeIdentity;
    }
  }

  selectAssume(assume:any,employeeid:any){
    this.assumeSelect=assume;
    this.assumeEmployeeId=employeeid;
    sessionStorage.setItem("assumeEmployeeid",employeeid);
  }

  assume(){
    this.lookup.getAssumeInfo(this.assumeEmployeeId).subscribe(data=>{
      this.assumeSelect='';
      sessionStorage.setItem("assumeUsername",data.body.response.username);
      sessionStorage.setItem("assumeIsmanagement",data.body.response.ismanagement);
      sessionStorage.setItem("assumePermissions",data.body.response.permissions);
      sessionStorage.setItem("assumePurchasinggroupid",data.body.response.purchasinggroupid);
      if(sessionStorage.getItem("page")==="home"){
      this.redirect('home'); }
    })
  }

  cancelassume(){
    sessionStorage.setItem("assumeEmployeeid",'653');
    this.assumeSelect='';
    sessionStorage.removeItem("assumeUsername");
    sessionStorage.removeItem("assumeIsmanagement");
    sessionStorage.removeItem("assumePermissions");
    sessionStorage.removeItem("assumePurchasinggroupid");
    if(sessionStorage.getItem("page")==="home"){
      this.redirect('home'); }
  }

  redirect(uri:string){
    this.router.navigateByUrl('/admin', {skipLocationChange: true}).then(()=>
    this.router.navigate([uri]));
 }
}
