<div clas="container-fluid">
    <div class="mt-3 m-4">
        <mat-tab-group animationDuration="0ms" (selectedIndex)="selectedTabIndex"
            (selectedTabChange)="onTabChanged($event)">

            <form [formGroup]="adminForm">
                <mat-tab label="ISE">
                    <div class="row subHeading m-4">
                        This tab is to mark a supplier as active/inactive
                    </div>
                    <div class="row pb-5 ">
                        <div class="col-md-6 ms-3 pl-3">
                            <div class="boxHeader">
                                <div class="d-flex justify-content-between p-1">
                                    <span class=" cursorptr ms-2 commonFont ">ISE </span>
                                    <span class="cursorptr me-2  commonFont" style="color:#2688DA" (click)="toggle()">Toggle ISE </span>
                                </div>
                                <div class="cursorptr ms-2 d-flex" >
                                    <input type="text" class="form-control addsearch search-tmna "
                                    placeholder="Search here">
                                    <img class="ms-1 searchimg" src="../assets/images/search.svg" alt="img">

                                </div>

                            </div>

                            <div class="boxISE me-1">
                                <option class="displayTextcss" *ngFor="let suppList of supplierList"
                                (click)="onItemSelection(suppList.description,suppList.appparentid,suppList.inactive)" 
                                [class.current-selection]="suppList.description===descrip">
                                {{suppList.description}} - <span *ngIf="suppList.inactive===false">Active</span> <span *ngIf="suppList.inactive!==false">Inactive</span>
                                </option>
                            </div>
                        </div>

                    </div>
                    <div>

                    </div>

                </mat-tab>
                <mat-tab label="Standard IET">
                    <div class="row subHeading m-3">This tab is to add, modify, or delete standard IETs, and to copy
                        IETs from the previous year
                    </div>
                    <div class="d-flex flex-row m-3 ">
                        <div class="col-md-5">
                            <div class="boxHeader commonFont">
                                <div class="d-flex ">
                                    <span class="ms-2 mt-2">STANDARD IETs </span>


                                </div>
                                <div class="p-2 cursorptr">
                                    <input type="text" class="form-control addsearch search-tmna "
                                        placeholder="Search here">
                                        <img class="ms-1" src="../assets/images/Search.svg" alt="img">
                                </div>

                            </div>
                            <div class="boxISE me-1 p-2 mb-7 ">
                                <div class="accordion" id="accordionExample"
                                    *ngFor="let category of standardIETsAccordianValue;let i = index">
                                    <div class="">
                                        <div class="d-flex" [id]="'heading'+i">

                                            <button class="btn headButton" type="button" data-bs-toggle="collapse"
                                                (click)="category.toggle =!category.toggle ;selectedSubnode(category,'second')"
                                                [attr.data-target]="'#collapse'+i" [attr.aria-controls]="'collapse'+i"
                                                aria-expanded="true" aria-controls="collapseOne">

                                                <img class="clpseBtn collapsesearchfilterBtn"
                                                    [ngClass]="{'active':category.toggle }" alt="img">
                                            </button>
                                            <span class="accordianTitle mt-1"> {{category.title}}</span>
                                        </div>
                                        <div [id]="'collapse'+i" class="collapse show cursorptr"
                                            *ngFor="let item of category.content" data-bs-parent="#accordionExample"
                                            [ngClass]="{ 'show': category.toggle }"
                                            [attr.aria-labelledby]="'heading'+i">
                                            <div class="accordianTitle ms-5" style="font-size: 12px;"
                                                (click)="selectedSubnode(item,'third')">
                                                {{item}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-7">
                            <div class="accordion-wrapper  ">
                                <div class="accordion mb-3" id="homeAccordianOne">
                                    <div class="card">
                                        <div class="card-header">
                                            <div class="d-flex flex-row justify-content-between">
                                                <span class="commonFont pt-2">INDIVIDUAL EXPECTED TASK</span>
                                                <button class="btn headButton" type="button" data-bs-toggle="collapse"
                                                    id="accordion3Btn" accordianActive="true"
                                                    data-bs-target="#collapsesearchfilter" aria-expanded="true"
                                                    aria-controls="collapsesearchfilter" (click)="isOpen=!isOpen">

                                                    <img class="clpseBtn collapsesearchfilterBtn"
                                                        [ngClass]="{'active':isOpen}" alt="img">
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="collapsesearchfilter" class="collapse show" aria-labelledby="headingOne"
                                    data-bs-parent="#proposalHist">
                                    <div class="cardBody">
                                        <div class="accordion-main p-2 mb-5">
                                            <mat-tab-group mat-stretch-tabs animationDuration="0ms"
                                                (selectedIndex)="selectedTabIndex"
                                                (selectedTabChange)="onTabChanged($event)">
                                                <mat-tab label="IET Properties">
                                                    <div class="row mt-3" *ngIf="showTab=='first'">
                                                        <div *ngIf="showTab =='first'" class="d-flex">
                                                            <label class="label-tmna mt-2 ms-2">Add IETs :
                                                                <input class="input-tmna ms-3" type="text"
                                                                    placeholder="Enter Name">
                                                            </label>

                                                            <button class="closeButton ms-3 mt-2"
                                                                style="width: fit-content; height: 35px;">ADD+</button>

                                                        </div>
                                                    </div>
                                                    <div class="row mt-3 ms-2" *ngIf="showTab=='second'">
                                                        <div class="d-flex flex-column">
                                                            <label class="label-tmna ">Title :<input class="input-tmna ms-2 "
                                                                    type="text"
                                                                    formControlName="iseTabNodeValue"></label>
                                                        </div>
                                                        <div class="label-tmna mt-2 ">Body</div>
                                                        <div class=""> <textarea class="label-tmna-big  "
                                                                style="width: 90%;" type="text"
                                                                placeholder="Place your text here" rows="5"
                                                                formControlName="isetabNodeExplanation"></textarea>
                                                        </div>
                                                        <div class="d-flex accordianTitle mt-2"
                                                            style="text-decoration: underline;">
                                                            <a class="ms-2 cursorptr">Save</a>
                                                            <a class="ms-2 cursorptr">Add IET Bullet</a>
                                                            <a class="ms-3 cursorptr">Delete IET Bullet</a>
                                                        </div>

                                                    </div>
                                                    <div class="row mt-3" *ngIf="showTab=='third'">
                                                        <div class="label-tmna ms-3">Bullet :</div>
                                                        <div class="row p-2 ms-1"> <textarea class="label-tmna-big ms-3 "
                                                                style="width: 80%;" type="text"
                                                                placeholder="Place your text here" rows="4"
                                                                formControlname="isetabNodeExplanation"></textarea>
                                                        </div>
                                                        <div class="d-flex accordianTitle mt-2"
                                                            style="text-decoration: underline;">
                                                            <a class="ms-3">Save</a>
                                                            <a class="ms-3">Delete IET Bullet</a>
                                                        </div>

                                                    </div>


                                                </mat-tab>

                                            </mat-tab-group>


                                        </div>

                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>
                </mat-tab>
                <mat-tab label="General Priorities">
                    <div class="row subHeading m-3">Here work needs to be done

                    </div>
                    <div class="d-flex flex-row m-3 ">
                        <div class="col-md-6">
                            <div class="boxHeader commonFont">
                                <div class="d-flex ">
                                    <span class="ms-2 mt-2">STANDARD IETs </span>


                                </div>
                                <div class="p-2">
                                    <input type="text" class="form-control addsearch search-tmna "
                                        placeholder="Search here">
                                </div>

                            </div>
                            <div class="boxISE me-1 p-2 mb-7 ">
                                <div class="accordion" id="accordionExample"
                                    *ngFor="let category of standardIETsAccordianValue;let i = index">
                                    <div class="">
                                        <div class="d-flex" [id]="'heading'+i">

                                            <button class="btn headButton" type="button" data-bs-toggle="collapse"
                                                (click)="category.toggle =!category.toggle ;selectedSubnode(category,'second')"
                                                [attr.data-target]="'#collapse'+i" [attr.aria-controls]="'collapse'+i"
                                                aria-expanded="true" aria-controls="collapseOne">

                                                <img class="clpseBtn collapsesearchfilterBtn"
                                                    [ngClass]="{'active':category.toggle }" alt="img">
                                            </button>
                                            <span class="accordianTitle mt-1"> {{category.title}}</span>
                                        </div>
                                        <div [id]="'collapse'+i" class="collapse show"
                                            *ngFor="let item of category.content" data-bs-parent="#accordionExample"
                                            [ngClass]="{ 'show': category.toggle }"
                                            [attr.aria-labelledby]="'heading'+i">
                                            <div class="accordianTitle ms-5" style="font-size: 12px;"
                                                (click)="selectedSubnode(item,'third')">
                                                {{item}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="accordion-wrapper  ">
                                <div class="accordion mb-3" id="homeAccordianOne">
                                    <div class="card">
                                        <div class="card-header">
                                            <div class="d-flex flex-row justify-content-between">
                                                <span class="commonFont pt-2">INDIVIDUAL EXPECTED TASK</span>
                                                <button class="btn headButton" type="button" data-bs-toggle="collapse"
                                                    id="accordion3Btn" accordianActive="true"
                                                    data-bs-target="#collapsesearchfilter" aria-expanded="true"
                                                    aria-controls="collapsesearchfilter" (click)="isOpen=!isOpen">

                                                    <img class="clpseBtn collapsesearchfilterBtn"
                                                        [ngClass]="{'active':isOpen}" alt="img">
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="collapsesearchfilter" class="collapse show" aria-labelledby="headingOne"
                                    data-bs-parent="#proposalHist">
                                    <div class="cardBody">
                                        <div class="accordion-main p-2 mb-5">
                                            <mat-tab-group mat-stretch-tabs animationDuration="0ms"
                                                (selectedIndex)="selectedTabIndex"
                                                (selectedTabChange)="onTabChanged($event)">
                                                <mat-tab label="IET Properties">
                                                    <div class="row mt-3" *ngIf="showTab=='first'">
                                                        <div *ngIf="showTab =='first'" class="d-flex">
                                                            <label class="label-tmna mt-2 ms-2">Add IETs :
                                                                <input class="input-tmna ms-3" type="text"
                                                                    placeholder="Enter Name">
                                                            </label>

                                                            <button class="closeButton ms-3 mt-2"
                                                                style="width: fit-content; height: 35px;">ADD+</button>

                                                        </div>
                                                    </div>
                                                    <div class="row mt-3" *ngIf="showTab=='second'">
                                                        <div class="label-tmna ">Bullet</div>
                                                        <div class="row p-2"> <textarea class="label-tmna-big ms-5 "
                                                                style="width: 80%;" type="text"
                                                                placeholder="Place your text here" rows="4"
                                                                formControlname="isetabNodeExplanation"></textarea>
                                                        </div>
                                                        <div class="d-flex accordianTitle"
                                                            style="text-decoration: underline;">
                                                            <a class="ms-2">Save</a>
                                                            <a class="ms-3">Delete IET Bullet</a>
                                                        </div>

                                                    </div>
                                                    <div class="row mt-3" *ngIf="showTab=='third'">
                                                        <div class="d-flex flex-column">
                                                            <label class="label-tmna ">Title :<input class="input-tmna"
                                                                    type="text"></label>
                                                        </div>
                                                        <div class="label-tmna ">Body</div>
                                                        <div class="row p-2"> <textarea class="label-tmna-big ms-5 "
                                                                style="width: 80%;" type="text"
                                                                placeholder="Place your text here" rows="4"></textarea>
                                                        </div>
                                                        <div class="d-flex accordianTitle"
                                                            style="text-decoration: underline;">
                                                            <a class="ms-2">Save</a>
                                                            <a class="ms-2">Add IET Bullet</a>
                                                            <a class="ms-3">Delete IET Bullet</a>
                                                        </div>

                                                    </div>

                                                </mat-tab>

                                                <mat-tab label="Fiscal Year">
                                                    <div class="row mt-3 mb-3">
                                                        <div class="d-flex ms-2">
                                                            <label class="label-tmna mt-1 me-3">Curent Fiscal Year :
                                                                <input type="text" class="input-tmna" placeholder="2024"
                                                                    style="width: 70px;"></label>

                                                            <button class="closeButton ms-3"
                                                                style="width: fit-content; height: 35px;">POPULATE
                                                                SUPPLIER LIST</button>
                                                            <button class="addButton ms-3"
                                                                style="width: fit-content; height: 35px;">UPDATE APP
                                                                LEAD BUYER</button>

                                                        </div>

                                                    </div>
                                                    <div class="row mb-3 boxHeaderForIET"
                                                        style="height: fit-content;background-color: #F4F0DF;">
                                                        <p class="label-tmna ms-2">This can be ran after the APP fiscal
                                                            year is switched to a new year. It populates the supplier
                                                            list for the new year. This will also copy the APP flags
                                                            from the previous year. The standard IETs will also be
                                                            copied</p>
                                                        <button class="addButton m-3 " style="width: fit-content; ">SET
                                                            TIER 2 DATE</button>

                                                    </div>

                                                </mat-tab>

                                                <mat-tab label="Copy IETs from Previous Year">
                                                    <div class="row flex-row mt-3 mb-3">
                                                        <div class="col-md-6">
                                                            <label class="label-tmna me-1 ms-2"> Previous Year:
                                                                <input class="input-tmna ms-1" type="text">
                                                            </label>

                                                        </div>
                                                        <div class="col-md-6">
                                                            <label class="label-tmna me-1"> Current Year: <input
                                                                    class="input-tmna ms-1" type="text">
                                                            </label>

                                                        </div>

                                                    </div>
                                                    <div class="row">
                                                        <div class="boxHeaderForIET" style="height: 40px;">
                                                            EDITING IETs
                                                        </div>
                                                        <div class="row">
                                                            <div class="d-flex m-3">
                                                                <input type="checkbox" class="ms-2">
                                                                <label class="label-tmna ms-1 me-4">Open <input
                                                                        type="checkbox" class="ms-2"></label>

                                                                <label class="label-tmna ms-1 me-4">Closed
                                                                    <input type="checkbox" class="ms-2">
                                                                </label>

                                                                <label class="label-tmna ms-1 me-4">Pending
                                                                    <input type="checkbox" class="ms-2">
                                                                </label>

                                                                <label class="label-tmna ms-1 me-4">In-Progress
                                                                    <input type="checkbox" class="ms-2">
                                                                </label>


                                                            </div>

                                                        </div>
                                                        <div class="boxHeaderForIET"
                                                            style="height: 50px; background-color: #F4F0DF;">
                                                            <button class="addButton mt-1 me-2"
                                                                style="width: fit-content;">Execute</button>


                                                        </div>
                                                    </div>
                                                    <div class="row mb-4">
                                                        <label class="label-tmna ms-2">This is the process that will
                                                            copy over IETs and IET Bullets from last year. Check the
                                                            status of the IETs you would like to copy and click
                                                            Execute.</label>

                                                    </div>

                                                </mat-tab>
                                            </mat-tab-group>


                                        </div>

                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>
                </mat-tab>
                <mat-tab label="Improvement Target">
                    <div class="row subHeading m-2">
                        <div class="d-flex justify-content-between">
                            <div>
                                <span class="mt-1">This tab is to add, modify/update TEMA Value Improvement Target
                                    options</span>
                            </div>
                            <div class="d-flex">
                                <button class="closeButton me-3" style="width: fit-content;">Refresh Target
                                    Description</button>
                                <button class="addButton ms-2" style="width: fit-content;">ADD+</button>

                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="commonFont ms-3">
                            <span><input type="checkbox"></span>
                            Exclude Value Improvement Wording from ISE Report

                        </div>
                        <div class="commonFont ms-3">
                            <span><input type="checkbox"></span>
                            Exclude APR Cost Reduction Target from ISE Report
                        </div>
                    </div>
                    <div class="flex-row p-1">
                        <ag-grid-angular class="ag-theme-balham " [rowData]="rowDataForImprovementTarget"
                            [columnDefs]="columnDefForImprovementTarget" (gridReady)="onGridReady($event)" [pagination]="true"
                            headerHeight="50" [defaultColDef]="defaultColDef" [paginationPageSize]="paginationPageSize"
                            [domLayout]="domLayout">

                        </ag-grid-angular>

                    </div>

                </mat-tab>
                <mat-tab label="Supplier flag">
                    <div class=" row subHeading m-3">This tab is to add, modify/update APP/BPM flag options and
                        descriptions</div>
                </mat-tab>
                <mat-tab label="Apply Standard IET to Supplier">
                    <div class="row m-3">
                        <div class="heading"> standard IETs</div>
                        <div class="subHeading">This tab is to Apply Standard IETs to Suppliers.</div>
                    </div>
                    <div class="col-md-6 p-2">Box1
                        <div class="boxHeader commonFont">
                            <div class="d-flex justify-content-between ">
                                <span class="ms-2 mt-1 ">STANDARD IETs </span>
                                <button class="closeButton mt-1 me-2" style="width: fit-content;">ALL/NONE</button>


                            </div>
                            <div class="p-2">
                                <input type="text" class="form-control addsearch search-tmna "
                                    placeholder="Search here">
                            </div>

                        </div>
                        <div class="boxISE me-1 p-2  ">

                            <option class="displayTextcss" *ngFor="let suppList of supplierList">{{suppList}}</option>

                        </div>
                        <div class="boxHeader justify-content-end  ">
                            <button class="closeButton ms-3 me-2 mt-2" style="width: fit-content;">Refresh</button>
                            <button class="addButton ms-3 ms-2 mt-2" style="width: fit-content;">APPLY</button>

                        </div>

                    </div>
                </mat-tab>
                <mat-tab label="Emailer">
                    <app-emailer></app-emailer>
                </mat-tab>
                <mat-tab label="Fiscal Year">
                    <mat-tab-group mat-stretch-tabs animationDuration="0ms"
                    (selectedIndex)="selectedTabIndex"
                    (selectedTabChange)="onTabChanged($event)" class="mt-3">
                   
                    <mat-tab label="Fiscal Year">
                        <div class="row mt-3 mb-4">
                            <div class="d-flex ms-3">
                                <label class="label-tmna mt-1 me-4">Current Fiscal Year :
                                    <input type="text" class="input-tmna ms-2" placeholder="2024"
                                        style="width: 70px;"></label>

                                <button class="closeButton ms-3 mt-1"
                                    style="width: 150px; height: 35px;">POPULATE
                                    SUPPLIER LIST</button>
                                <button class="addButton ms-3 mt-1"
                                    style="width: 150px; height: 35px;">UPDATE APP
                                    LEAD BUYER</button>

                            </div>

                        </div>
                        <div class="row mb-3 boxHeaderForIET mt-2"
                            style="height: fit-content;background-color: #F4F0DF;">
                          <div>
                            <p class="label-tmna ms-2 text-wrap">This can be ran after the APP fiscal
                                year is switched to a new year. It populates the supplier
                                list for the new year. This will also copy the APP flags
                                from the previous year. The standard IETs will also be
                                copied</p>
                          </div>
                           
                            <button class="addButton ms-3 mb-4 " style="width: fit-content; ">SET
                                TIER 2 DATE</button>
                          

                        </div>

                    </mat-tab>

                    <mat-tab label="Copy IETs from Previous Year">
                        <div class="row flex-row mt-3 mb-3">
                            <div class="col-md-6">
                                <label class="label-tmna  ms-2"> Previous Year:
                                    <input class="input-tmna ms-1" type="text">
                                </label>

                            </div>
                            <div class="col-md-6">
                                <label class="label-tmna me-1"> Current Year: <input
                                        class="input-tmna ms-1" type="text">
                                </label>

                            </div>

                        </div>
                        <div class="row mt-3">
                           <div class="editingIetBox">
                            <div class="boxHeaderForIET  p-2" style="height: 50px;">
                               <span class="commonFont ms-1 "> EDITING IETs</span>
                            </div>
                            <div class="row">
                                <div class="d-flex m-3">
                                   
                                    <label class="label-tmna ms-1 me-4">Open <input
                                            type="checkbox" class="ms-2"></label>

                                    <label class="label-tmna ms-1 me-4">Closed
                                        <input type="checkbox" class="ms-2">
                                    </label>

                                    <label class="label-tmna ms-1 me-4">Pending
                                        <input type="checkbox" class="ms-2">
                                    </label>

                                    <label class="label-tmna ms-1 me-4">In-Progress
                                        <input type="checkbox" class="ms-2">
                                    </label>


                                </div>

                            </div>
                            <div class="boxHeaderForIET "
                                style="height: 60px; background-color: #F4F0DF;">
                             <div class="d-flex justify-content-between">
                                <div>
                                    <button class="addButton mt-2 ms-2"
                                    style="width:100px;">Execute</button>
                                  </div>
                                  <div style="visibility: hidden;">
                                    toyota
                                  </div>

                             </div>


                            </div>

                           </div>
                        </div>
                        <div class="row mb-4 mt-2">
                            <label class="label-tmna ms-2">This is the process that will
                                copy over IETs and IET Bullets from last year. Check the
                                status of the IETs you would like to copy and click
                                Execute.</label>

                        </div>

                    </mat-tab>
                </mat-tab-group>
                   
                </mat-tab>
            </form>

        </mat-tab-group>

    </div>


</div>