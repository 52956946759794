<div class="row mt-3" style="padding-bottom: 190px; padding-right: 10px;">
    <div class="d-flex justify-content-between">
        <div class="col-6 col-md-6 me-1" style="width: 50%;">
            <ag-grid-angular  class="ag-theme-balham me-4" [rowData]="rowDataForEmailer"  [columnDefs]="columnDefForEmailer"
                (gridReady)="onGridReady($event)" headerHeight="50" [defaultColDef]="defaultColDef"
                [domLayout]="domLayout"
                [floatingFiltersHeight]="50" style="margin-right: 0px !important;"
                [rowSelection]="rowSelection" (selectionChanged)="onSelection()">
            </ag-grid-angular>
        </div>
        <div class="col-6 col-md-6 ms-1" style="width: 50%;">
            <span class="emailListPreviewHeader"> EMAIL LIST PREVIEW DISPLAY </span>
            <div class="cardBody mt-2 pb-2 pt-1">
                <div class="col d-flex">
                    <div class="col-md-5 me-2">
                        <div class="row m-1 pb-1">
                            <label for="reportPeriod" class="label-tmna">Report Period:</label>
                            <select id="reportPeriod" class="dropdown-tmna ms-2">
                                <option class="displayTextcss" *ngFor="let item of reportPeriodData" [value]="item">
                                    {{item}}
                                </option>
                            </select>
                        </div>
                        <div class="row m-1">
                            <label for="supplierFlag" class="label-tmna">Supplier Flag:</label>
                            <select id="supplierFlag" class="dropdown-tmna ms-2">
                                <option class="displayTextcss" *ngFor="let item of supplierFlagData" [value]="item">
                                    {{item}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-7">
                        <div class="m-2 sendResendDetails" style="font-size: small;">
                            <div class="ms-2 flex-row">
                                <label for="lastSend" class="label-tmna">Last Send:</label>
                                <span class="label-tmna">Mar 6 2023 3:19 PM</span>
                            </div>
                             
                            <div class="ms-2 flex-row">
                                <label for="lastSendTest" class="label-tmna">Last Send Test:</label>
                                <span class="label-tmna">Not Sent</span>
                            </div>

                            <div class="ms-2 flex-row">
                                <label for="lastReend" class="label-tmna">Last Resend:</label>
                                <span class="label-tmna">Mar 6 2023 3:19 PM</span>
                            </div>
                             
                            <div class="ms-2 flex-row">
                                <label for="lastResendTest" class="label-tmna">Last Resend Test:</label>
                                <span class="label-tmna">Not Sent</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="reportDetails">
                    <div class="d-flex m-2 pb-2">
                        <div class="col-md-3"><label for="reportPeriod" class="label-tmna ms-2">Report Period:</label></div>
                        <div class="col-md-9"> <input class="input-tmna" style="width: 100%;" type="text" placeholder="Enter report period"></div>
                    </div>
                    <div class="d-flex m-2 pb-2">
                        <div class="col-md-3"><label for="emailBody" class="label-tmna ms-2">Email Body:</label></div>
                        <div class="col-md-9"> <textarea class="label-tmna-big" style="width: 100%;" type="text" rows="8" placeholder="Enter email body"></textarea></div>
                    </div>
                    <div class="d-flex m-2 pb-2">
                        <div class="col-md-3"><label for="emailBodySurveyLink" class="label-tmna ms-2">Email Body for Survey Link:</label></div>
                        <div class="col-md-9 d-flex flex-column" style="gap: 10px;"> 
                            <textarea class="label-tmna-big" style="width: 100%;" type="text" placeholder="Email body survey link" rows="4"></textarea>
                            <div class="d-flex">
                                <input type="checkbox" style="margin-right: 10px;box-shadow: none;"> <span class="label-tmna">Exclude survey link</span>
                            </div>
                            <span class="attachmentTooltip">
                                Add an Attachment with the Browser… and add it by clicking “Save Content”. You can remove an Attachment by checking an Attachment and clicking “Remove Att”. You are limited to an individual file size of 5MB
                            </span>
                        </div>
                    </div>
                    <div class="d-flex m-2 pb-2">
                        <div class="col-md-3">
                            <label for="attachment" class="label-tmna ms-2">Attachment:</label>
                        </div>
                        <div class="col-md-9">
                            <div class="d-flex attachingFiles">
                                <div class="d-flex file-item" style="padding-bottom: 10px; gap: 10px;" *ngFor = "let file of files; let i = index">
                                    <input type="checkbox" class="shadow-none" [(ngModel)]="file.isSelected" [ngModelOptions]="{standalone: true}" (change)="onCheckboxSelected()"/>
                                    <div class="attachmentSection">
                                        <label for="attachedFileName" class="label-tmna">{{file.name}}</label>
                                        <button type="button" class="btn-close" (click)="removeAttachment(i)" (keyup)="removeAttachment(i)">
                                            <img src="\assets\images\close-icon.png" height="15" width="15" alt="Remove Attachment">
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex" style="gap: 10px;">
                                <button class="closeButton" (click)="fileInput.click()" style="width: fit-content;">ATTACH FILE</button>
                                <input type="file" #fileInput (change)="onFileSelected($event)" style="display: none;"/>

                                <span class="label-tmna pt-2 noFilesChosen" *ngIf="!isAnyFilesChosen">No Files Chosen</span>

                                <button class="closeButton" style="width: fit-content;">SAVE EMAIL CONTENT</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="m-2 pb-2">
                    <div class="ms-2 recipientsToolTipContent">
                        <span class="label-tmna">
                            Test email recipients. ** Please use comma(,) to separate emails.
                        </span>
                        <textarea class="label-tmna-big" placeholder="Test email recipients content" style="width: 100%;" rows="4"></textarea>
                    </div>
                </div>  
            </div>
        </div>
    </div>
</div>
<nav class="navbar navbar-expand-lg justify-content-end footer-tab fixed-bottom sendTypeNav mt-2" aria-label="email send type" style="bottom: 60px;">
    <div class="saveFooter mb-3 d-flex flex-row">
        <label for="sendType" style="padding-right: 30px; font-weight: 500;">Send Type: </label>
        <div class="d-flex" style="gap: 10px;">
            <input type="checkbox" class="shadow-none mb-1"><span class="label-tmna">Test</span>
            <input type="checkbox" class="shadow-none mb-1"><span class="label-tmna">CC Lead Buyer</span>
            <label for="send" class="label-tmna me-2">
                <input type="radio" name="emailRadio" value="send" class="shadow-none"> Send
            </label>
            <label for="resend" class="label-tmna me-2">
                <input type="radio" name="emailRadio" value="resend" class="shadow-none"> Resend
            </label>
        </div>
    </div>
</nav>
<nav class="navbar navbar-expand-lg justify-content-end footer-tab fixed-bottom mt-2" aria-label="send email">
    <div class="saveFooter mb-3 ">
        <button class="addButton ms-3 me-2" style="width: fit-content;">SEND EMAIL</button>
    </div>
</nav>