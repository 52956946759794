<div class="container-fluid">
   <div class="col-md-12 m-3">
    <mat-tab-group mat-stretch-tabs animationDuration="0ms" (selectedIndex)="selectedTabIndex"
    (selectedTabChange)="onTabChanged($event)">
        <mat-tab label="Supplier Approval Status">
            <div class="row">
                <div class="d-flex justify-content-between">
                    <div class="mt-2"><span class="commonFont ms-3">This tab is to export a list of Supplier Status.</span>
                    </div>
                    <div class="mt-2 ">
                        <button class="closeButton me-4" style="width: fit-content;" (click)="exportDataToExcel(rowDataforSupplierApprovalStatus, columnDefSupplierApprovalStatus, 'APP Open Packet List')">
                            <img src="\assets\images\report.png" alt="Export Supplier List" height="16" width="16">
                            EXPORT SUPPLIER LIST
                        </button>
                    </div>
                </div>
            </div>
            <div class="row mt-2" style="margin-bottom: 50px">
                <div class="flex-row mt-2" id="dataForSupplierApprovalStatus">
                    <ag-grid-angular  class="ag-theme-balham me-4" [rowData]="rowDataforSupplierApprovalStatus"  [columnDefs]="columnDefSupplierApprovalStatus"
                        (gridReady)="onGridReady($event)" [pagination]="true" headerHeight="74" [defaultColDef]="defaultColDef"
                        [paginationPageSize]="paginationPageSize" [domLayout]="domLayout"
                        [floatingFiltersHeight]="50">
                    </ag-grid-angular>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="APR Targets">
            <div class="row">
                <div class="d-flex justify-content-between">
                    <div class="mt-2"><span class="commonFont ms-3">This tab is to export a list of Suppliers and APR Cost Reduction Targets.</span>
                    </div>
                    <div class="mt-2 ">
                        <button class="closeButton me-4" style="width: fit-content;" (click)="exportDataToExcel(rowDataforAPRTarget, columnDefAPRTargets, 'APP APR Cost Reduction Targets')">
                            <img src="\assets\images\report.png" alt="Export Supplier List" height="16" width="16">
                            EXPORT SUPPLIER LIST
                        </button>
                    </div>
                </div>
            </div>
            <div class="row mt-2" style="margin-bottom: 50px">
                <div class="flex-row mt-2">
                    <ag-grid-angular class="ag-theme-balham me-4" [rowData]="rowDataforAPRTarget"  [columnDefs]="columnDefAPRTargets"
                        (gridReady)="onGridReady($event)" [pagination]="true" headerHeight="74" [defaultColDef]="defaultColDef"
                        [paginationPageSize]="paginationPageSize" [domLayout]="domLayout" 
                        [floatingFiltersHeight]="50" >
                    </ag-grid-angular>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Tier II Results">
            <div class="row">
                <div class="d-flex justify-content-between">
                    <div class="mt-2"><span class="commonFont ms-3">This tab is to export a list of Supplier & Tier II Results.</span>
                    </div>
                    <div class="mt-2 ">
                        <button class="closeButton me-4" style="width: fit-content;"  (click)="exportDataToExcel(rowDataforTier2Results, columnDefTier2Results, 'APP Tier II Results')">
                            <img src="\assets\images\report.png" alt="Export Supplier List" height="16" width="16">
                            EXPORT SUPPLIER LIST
                        </button>
                    </div>
                </div>
            </div>
            <div class="row mt-2" style="margin-bottom: 50px">
                <div class="flex-row mt-2">
                    <ag-grid-angular class="ag-theme-balham me-4" [rowData]="rowDataforTier2Results"  [columnDefs]="columnDefTier2Results"
                        (gridReady)="onGridReady($event)" [pagination]="true" headerHeight="74" [defaultColDef]="defaultColDef"
                        [paginationPageSize]="paginationPageSize" [domLayout]="domLayout"
                        [floatingFiltersHeight]="50" >
                    </ag-grid-angular>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Supplier IET Summary">
            <div class="row">
                <div class="d-flex justify-content-between">
                    <div class="mt-2"><span class="commonFont ms-3">This tab is to export a list of Suppliers and APR Cost Reduction Targets.</span>
                    </div>
                    <div class="mt-2">
                        <button class="closeButton me-4" style="width: fit-content;" (click)="exportDataToExcel(rowDataforSupplierIETSummary, columnDefSupplierIETSummary, 'APP IET Matrix')">
                            <img src="\assets\images\report.png" alt="Export Supplier List" height="16" width="16">
                            EXPORT SUPPLIER LIST
                        </button>
                    </div>
                </div>
            </div>
            <div class="row mt-2" style="margin-bottom: 50px">
                <div class="flex-row mt-2">
                    <ag-grid-angular class="ag-theme-balham me-4" [rowData]="rowDataforSupplierIETSummary"  [columnDefs]="columnDefSupplierIETSummary"
                        (gridReady)="onGridReady($event)" [pagination]="true" headerHeight="74" [defaultColDef]="defaultColDef"
                        [paginationPageSize]="paginationPageSize" [domLayout]="domLayout"
                        [floatingFiltersHeight]="50" >
                    </ag-grid-angular>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
   </div>
</div>
