<!-- Top Blue Box  -->
<div class="topBox">
  <span class="ms-4" style="color:#FBFBFC; font-size: size 12px;">APP Packet Approval Information  </span>
</div>
<!-- Main Content -->
<div class="container-fluid">
  <!-- Space for packet information in form of tab -->
  <div class="row">
    <div class="col-12 col-md-12 m-2">
      <div class="customBox d-flex" >
        <div class="col-md-2 ">
          <div class="innerCustomBox-before" (click)="applyActiveClass(1, $event)" (keyup)="applyActiveClass(1, $event)" [ngClass]="{innerCustomBox:tab1==true}">
            <div class="d-flex">
              <div class="commonFont">{{totalPacket}}</div>
              <div class="percentageBox label-tmna mt-2" style="visibility: hidden;">{{totalPacketPercentage}}%</div>
             </div>
              <div class="label-tmna">Total number of App packets</div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="innerCustomBox-before" (click)="applyActiveClass(2, $event)" (keyup)="applyActiveClass(2, $event)" [ngClass]="{innerCustomBox:tab2==true}">
           <div class="d-flex">
            <div class="commonFont">{{approvedpacket}}</div>
            <div class="percentageBox label-tmna mt-2">{{approvedpacketPercentage}}%</div>
           </div>
            <div class="label-tmna">Approved App packets</div>
        </div>
        </div>
        <div class="col-md-2">
          <div class="innerCustomBox-before" (click)="applyActiveClass(3, $event)" (keyup)="applyActiveClass(3, $event)" [ngClass]="{innerCustomBox:tab3==true}" >
            <div class="d-flex">
              <div class="commonFont">{{packetNotSent}}</div>
              <div class="percentageBox label-tmna mt-2">{{packetNotSentPercentage}}%</div>
             </div>
            <div class="label-tmna">App packets Not Sent</div>
        </div>
        </div>
        <div class="col-md-2">
          <div class="innerCustomBox-before" (click)="applyActiveClass(4, $event)" (keyup)="applyActiveClass(4, $event)" [ngClass]="{innerCustomBox:tab4==true}">
            <div class="d-flex">
              <div class="commonFont">{{openPacket}}</div>
              <div class="percentageBox label-tmna mt-2">{{openPacketPercentage}}%</div>
             </div>
            <div class="label-tmna">Open App packets</div>
        </div>
        </div>

      </div>
     
    </div>
  </div>
  <!-- Main content of the page -->
  <div class="flex-row d-flex">
    <!-- Supplier list details div start -->
    <div class="col-4 col-md-4 me-1 p-1  ">
      <div class="cursorptr searchBar p-1" style=" background-color: #E4ECF0;" >
        <input type="text" [(ngModel)]="searchSupplier" class="form-control addsearch search-tmna " placeholder="Search here">
        <img class="ms-1" src="\assets\images\search.svg" alt="img">
      </div>
      <div class="supplierBox commonFont">
        <span class="ms-2 pt-2">Suppliers {{currentYear}}</span>
      </div>
      <div class="supplierContainer">
        <option class="supplierList displayTextcss" *ngFor="let suppList of filteredSupplier; let i = index" (keyup)="selectSupplier(i, $event)" (click)="selectSupplier(i, $event)" [class.selected] = "i === selectedIndex">
          {{suppList}}
        </option>
      </div>
      <div class="mt-2 mb-2">
        <span class="label-tmna mt-2 mb-2">* Denotes you are the lead buyer for this supplier</span>
      </div>
    </div>
    <!-- Supplier list  ends here  -->
    <div class="col-8 col-md-8 ms-1 p-1">
      <!-- Heading and link on right side  -->
      <div class="row">
        <div class="d-flex justify-content-between p-2">
          <div class="d-flex flex-row ms-1">
            <span class="heading">{{selectedSupplierName}}</span>
          </div>
          <div class="d-flex flex-row me-2">
            <a class="homePageLinks me-2" href="/ise">View/Edit ISE</a>
            <a class="homePageLinks ms-2" href="/home">View Report</a>
          </div>

        </div>
      </div>
      <!-- Heading and link on right side ENDS HERE -->
      <!-- Accordian 1 starts with a row -->
      <div class="">
        <div class="accordion-wrapper ">
          <div class="accordion mb-2" id="homeAccordianOne">
            <div class="card">
              <div class="card-header">
                <div class="d-flex flex-row justify-content-between">
                  <span class="commonFont pt-2">SUPPLIER DETAILS</span>
                  <button class="btn headButton" type="button" data-bs-toggle="collapse" id="accordion3Btn"
                    accordianActive="true" data-bs-target="#collapsesearchfilter" aria-expanded="true"
                    aria-controls="collapsesearchfilter" (click)="isOpen1=!isOpen1">
        
                    <img class="clpseBtn collapsesearchfilterBtn" [ngClass]="{'active':isOpen1}" alt="img">
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div id="collapsesearchfilter" class="collapse show" aria-labelledby="headingOne" data-bs-parent="#proposalHist">
            <div class="cardBody">
              <div class="accordion-main">
                <div class="row">
                  <div class="d-flex flex-row">
                    <div class="col-5 col-md-5 mt-3 ms-3 me-3 pe-3">
                      <div class="d-flex flex-row">
                        <label for="imprTargetTitle" class="label-tmna me-2"> TMNA Value Improvement Target</label>
                        <select id="imprTargetTitle" [(ngModel)]="selectedImprTargetTitle" (change)="onSelectingTitle()" class=" dropdown-tmna ">
                          <option class="displayTextcss" *ngFor="let item of improvementTargetData" [value]="item.title">
                            {{item.title}}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-5 col-md-5 mt-3 ms-3">
                      <div class="d-flex flex-row">
                        <span class="label-tmna me-2 pe-3"> APR Cost Reduction Target(Kadai)%</span>
                        <div>
                          <div class="d-flex aprValueFlex">
                            <div class="me-2 row">
                              <label for="vipmanuimpraprtarget" class="label-tmna centerLabel">FY25</label>
                              <input class="input-tmna" type="number" id="vipmanuimpraprtarget" [value]="currentAPRtarget">
                            </div>
                            
                            <div class="me-2 row">
                              <label for="previoustarget" class="label-tmna centerLabel">FY24</label>
                              <input class="input-tmna" type="number" id="previoustarget" [value]="prevAPRtarget" disabled>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row pb-3">
                  <label for="imprTargetDesc" class="label-tmna ms-3">Enter details</label>
                  <textarea class="label-tmna-big ms-4" id="imprTargetDesc" [value]="selectedImprTargetDesc" rows="5" readonly></textarea>
                </div>
              </div>
        
            </div>
          </div>
        </div>
      </div>
      <!-- Accordian 1 ends here -->
      <div class="d-flex flex-row mt-3 mb-2">
        <div class="accordianwidth1 me-1" style="min-width: 50%;">
          <div class="accordion-wrapper">
            <div class="accordion mb-2" id="homeAccordianTwo">
              <div class="card">
                <div class="card-header">
                  <div class="d-flex flex-row justify-content-between">
                    <span class="commonFont pt-2">Safety Risk Ranking </span>
                    <button class="btn headButton" type="button" data-bs-toggle="collapse" id="accordion3Btn"
                      accordianActive="true" data-bs-target="#riskRanking" aria-expanded="true"
                      aria-controls="collapsesearchfilterTwo" (click)="isOpen2=!isOpen2">

                      <img class="clpseBtn collapsesearchfilterBtn" [ngClass]="{'active':isOpen2}" alt="img">
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div id="riskRanking" class="collapse show" aria-labelledby="headingOne"
              data-bs-parent="#proposalHist">
              <div class="cardBody">
                <div class="accordion-main p-2">
                  
                  <ag-grid-angular class="ag-theme-balham " [rowData]="rowDataSafetyRisk" [columnDefs]="columnDefsSafetyRisk"
                    (gridReady)="onGridReady($event)" [pagination]="true" headerHeight="50" [defaultColDef]="defaultColDef"
                    [paginationPageSize]="paginationPageSize" [domLayout]="domLayout">
                  
                  </ag-grid-angular>
                  

                </div>



              </div>

            </div>
            

          </div>
        </div>
        <div class="flex-column">
          <div class="accordianwidth ms-1">
            <div class="accordion-wrapper">
              <div class="accordion mb-2" id="homeAccordianTwo">
                <div class="card">
                  <div class="card-header">
                    <div class="d-flex flex-row justify-content-between">
                      <span class="commonFont">Tier II Sourcing Section</span>
                      <button class="btn headButton" type="button" data-bs-toggle="collapse" id="accordion3Btn"
                        accordianActive="true" data-bs-target="#tierTwoSourcing" aria-expanded="true"
                        aria-controls="collapsesearchfilterTwo" (click)="isOpen3=!isOpen3">
  
                        <img class="clpseBtn collapsesearchfilterBtn" [ngClass]="{'active':isOpen3}" alt="img">
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div id="tierTwoSourcing" class="collapse show" aria-labelledby="headingOne"
                data-bs-parent="#proposalHist">
                <div class="cardBody">
                  <div class="accordion-main p-3 row">
                    <div class="tieriiResult">
                      <div class="ms-2 col targetValue" style="min-height: 45px;">
                        <label for="tieriiminoritysourcingtarget" class="label-tmna tlabel1">Minority Target % (See Tier 2 Manual)</label>
                        <input class="input-tmna" type="number" id="tieriiminoritysourcingtarget" [value]="minoritytarget" disabled>
                      </div>

                      <div class="ms-2 col resultValue">
                        <label for="prevMBEachievement" class="label-tmna">Spring {{currentYear}} %</label>
                        <input class="input-tmna" type="text" id="prevMBEachievement" [value]="prevMBEachievement" disabled>
                      </div>
                     
                      <div class="ms-2 col resultValue">
                        <label for="currentMBEachievement" class="label-tmna">Spring {{currentYear}} %</label>
                        <input class="input-tmna" type="text" id="currentMBEachievement" [value]="currentMBEachievement" disabled>
                      </div>
                      
                    </div>
                    <div class="tieriiResult">
                      <div class="me-2 col targetValue">
                        <label for="tieriiwbetarget" class="label-tmna tlabel2" style="min-height: 45px;">Women Target %</label>
                        <input class="input-tmna" type="number" id="tieriiwbetarget" [value]="wbetarget" disabled>
                      </div>

                      <div class="me-2 col resultValue">
                        <label for="prevWBEachievement" class="label-tmna">Fall {{currentYear}} %</label>
                        <input class="input-tmna" type="text" id="prevWBEachievement" [value]="prevWBEachievement" disabled>
                      </div>
                     
                      <div class="me-2 col resultValue">
                        <label for="currentWBEachievement" class="label-tmna">Fall {{currentYear}} %</label>
                        <input class="input-tmna" type="text" id="currentWBEachievement" [value]="currentWBEachievement" disabled>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="removeDetailsBox p-3">
        <div class="commonFont">Need to Remove a supplier from your list ?</div>
        <div class="label-tmna">- If the plants still supply for buyers or need to remain active for service, please inactivate all of the (OE, Prod) part records assigned to your code in Part Master </div>
        <div class="label-tmna">- If plant is not longer supplying to TMNA at all, please submit the vendor packet to inactivate the plant code in WARP</div>

      </div>
      <div class="d-flex mt-2 ">
        <div class="accordion-wrapper" style="margin-bottom: 7em;">
          <div class="accordion mb-2" id="homeAccordianTwo">
            <div class="card">
              <div class="card-header">
                <div class="d-flex flex-row justify-content-between">
                  <span class="commonFont pt-2">Commodity Details</span>
                  <button class="btn headButton" type="button" data-bs-toggle="collapse" id="accordion3Btn"
                    accordianActive="true" data-bs-target="#commodityDetails" aria-expanded="true"
                    aria-controls="collapsesearchfilterTwo" (click)="isOpen4=!isOpen4">

                    <img class="clpseBtn collapsesearchfilterBtn" [ngClass]="{'active':isOpen4}" alt="img">
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div id="commodityDetails" class="collapse show" aria-labelledby="headingOne"
            data-bs-parent="#proposalHist">
            <div class="cardBody">
              <div class="accordion-main p-2">
                <ag-grid-angular class="ag-theme-balham " [rowData]="rowDataCommodityDetails" [columnDefs]="columDefCommodityDetails"
                  (gridReady)="onGridReady($event)" [pagination]="true" [defaultColDef]="defaultColDef"
                  [paginationPageSize]="paginationPageSize" [domLayout]="domLayout" headerHeight="50">
                
                </ag-grid-angular>

              </div>



            </div>

          </div>
          

        </div>
       
      </div>

    </div>
  </div>
</div>



<nav class="navbar  navbar-expand-lg justify-content-end footer-tab fixed-bottom mt-2">
  <div class="saveFooter mb-2 " >
    <button class="addButton ms-3 me-2">ACCEPT</button>
    <button class="closeButton ms-3" style="width: 130px;">DO NOT SEND</button>
    <button class="closeButton ms-3">SAVE</button>

  </div>
</nav>







