<div class="container-fluid">
  <div class="row mt-1">
    <app-breadcrumb [breadcrumbitems]="breadcrumbItems"></app-breadcrumb>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="accordion-wrapper">
        <div class="accordion mb-3" id="homeAccordianOne">
          <div class="card">
            <div class="card-header">
              <div class="d-flex flex-row justify-content-between">
                <span class="commonFont pt-2">QUALITY & DELIVERY</span>
                <button class="btn headButton" type="button" data-bs-toggle="collapse" id="accordion3Btn"
                  accordianActive="true" data-bs-target="#collapsesearchfilter1" aria-expanded="true"
                  aria-controls="collapsesearchfilter1" (click)="isOpen1=!isOpen1">

                  <img class="clpseBtn collapsesearchfilterBtn" [ngClass]="{'active':isOpen1}" alt="img">
                </button>
              </div>
            </div>
          </div>
        </div>
        <div id="collapsesearchfilter1" class="collapse show" aria-labelledby="headingOne"
          data-bs-parent="#proposalHist">
          <div class="cardBody">
            <div class="accordion-main p-2">
              <div class="d-flex mt-3">
                <div class="col-md-2">
                  <span class="label-tmna ms-2">Quality PPM Defect Rate:</span>
                </div>
                <div class="col-md-10">
                  <div class="row">
                    <div class="col-6 col-md-3">
                      <div class="d-flex flex-column">
                        <span class="label-tmna">Target OE</span>
                        <input class="input-tmna" type="text" placeholder="Enter Target OE">
                      </div>
                    </div>
                    <div class="col-6 col-md-3">
                      <div class="d-flex flex-column">
                        <span class="label-tmna">Target Service</span>
                        <input class="input-tmna" type="text" placeholder="Enter Target Service">
                      </div>
                    </div>
                    <div class="col-4 col-md-2">
                      <div class="d-flex flex-column">
                        <span class="label-tmna">Goal Prototype</span>
                        <select class="dropdown-tmna" style="width: 50%;">
                          <option [disabled] value="0">0</option>
                          <option value="2">2</option>
                          <option value="5">5</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-6 col-md-3">
                      <div class="d-flex flex-column">
                        <span class="label-tmna">Delivery KPM</span>
                        <input class="input-tmna" type="text" placeholder="Enter Delivery KPM">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex mt-3">
                <div class="col-md-2">
                  <span class="label-tmna ms-2">Warranty Target%</span>
                </div>
                <div class="col-md-10" style="margin-bottom: 30px;">
                  <input class="input-tmna" type="text" placeholder="Enter Warranty Target%">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="accordion-wrapper mt-4">
    <div class="accordion mb-3" id="homeAccordianOne">
      <div class="card">
        <div class="card-header">
          <div class="d-flex flex-row justify-content-between">
            <span class="commonFont pt-2">COMMODITIES</span>
            <button class="btn headButton" type="button" data-bs-toggle="collapse" id="accordion3Btn"
              accordianActive="true" data-bs-target="#collapsesearchfilter2" aria-expanded="true"
              aria-controls="collapsesearchfilter2" (click)="isOpen2=!isOpen2">

              <img class="clpseBtn collapsesearchfilterBtn" [ngClass]="{'active':isOpen2}" alt="img">
            </button>
          </div>
        </div>
      </div>
    </div>
    <div id="collapsesearchfilter2" class="collapse show" aria-labelledby="headingOne" data-bs-parent="#proposalHist">
      <div class="cardBody">
        <div class="accordion-main p-2 mb-5">
          <div class="p-2">
            <ag-grid-angular class="ag-theme-balham " [rowData]="rowDataCommodities" [columnDefs]="columnDefCommodities"
              (gridReady)="onGridReady($event)" headerHeight="50" [defaultColDef]="defaultColDef"
              [domLayout]="domLayout">

            </ag-grid-angular>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<nav class="navbar navbar-expand-lg justify-content-end footer-tab mt-1">
  <div class="saveFooter mb-3 ">
    <button class="addButton ms-3 me-2">SAVE</button>
    <button class="closeButton ms-3">CANCEL</button>

  </div>
</nav>