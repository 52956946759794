import { Component, HostListener } from '@angular/core';
import { GridApi } from 'ag-grid-community';

@Component({
  selector: 'app-supplier-details',
  templateUrl: './supplier-details.component.html',
  styleUrl: './supplier-details.component.css'
})
export class SupplierDetailsComponent {
  isOpen1=true;
  isOpen2=true;
  gridApi:GridApi;
  gridColumnApi:any;
  domLayout='autoHeight';
  breadcrumbItems=[
    {
      label: 'Home',
      link: '/home',
      icon: '>'
    },
    {
      label: 'View/edit ISE',
      link: '/ise',
      icon: '>'
    },
    {
      label: '2500',
      link: null,
      icon: ''
    },
  ];

  defaultColDef={ 
    suppressNavigable: true,
    cellClass: 'border',
    suppressMovable: true,
    sortable: false,
    unSortIcon: true,
    wrapText: true,
    autoHeight: true
   };

   columnDefCommodities=[
    {
      headerName: 'Code',
      field: 'code',
      flex: 1
    },
    {
      headerName: 'Description',
      field: 'description',
      flex: 1
    },
    {
      headerName: 'Exclude',
      field: 'exclude',
      flex: 1,
      cellRenderer: (params) => {
        const checkbox = document.createElement('input');
        checkbox.type = 'checkbox';
        checkbox.checked = params.value;
        checkbox.addEventListener('change', (event) => {
          const isChecked = (event.target as HTMLInputElement).checked;
          params.setValue(isChecked);
        });
        return checkbox;
      }
    }
  ];

  rowDataCommodities=[
    {'code': '10021', 'description': 'Catalytic Converter1', 'exclude': false},
    {'code': '10022', 'description': 'Catalytic Converter2', 'exclude': false},
    {'code': '10023', 'description': 'Catalytic Converter3', 'exclude': false},
    {'code': '10024', 'description': 'Catalytic Converter4', 'exclude': false},
    {'code': '10025', 'description': 'Catalytic Converter5', 'exclude': false},
  ];

  onGridReady(params:any){
    this.gridApi = params?.api;
    this.gridColumnApi = params?.columnApi;
    this.gridApi?.sizeColumnsToFit(); 
  }
  @HostListener("window:resize", ['$event'])
  onResize(_event) {

    if (this.gridApi != null) {

      this.gridApi?.sizeColumnsToFit();
      this.gridApi.refreshCells();
    }

  }
}
