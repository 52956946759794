import { Component, HostListener } from '@angular/core';
import { GridApi } from 'ag-grid-community';
import { LookupService } from '../services/lookup.service';
import { SupplierCodeRendererComponent } from './supplier-code-renderer/supplier-code-renderer.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.css',
})

export class HomeComponent {
  currentYear = new Date().getFullYear();
  isOpen1 = true;
  isOpen2 = true;
  isOpen3 = true;
  isOpen4 = true;
  gridApi: GridApi;
  gridColumnApi: any;
  paginationPageSize = 6;
  domLayout = 'autoHeight';
  totalPacket = 0;
  approvedpacket = 0;
  packetNotSent = 0;
  openPacket = 0;
  approvedpacketPercentage = 0;
  packetNotSentPercentage = 0;
  openPacketPercentage = 0;
  isStyled = false;
  tab1 = true;
  tab2 = false;
  tab3 = false;
  tab4 = false;
  bodyContent: { fiscalYear: number; employeeId: number }; //Setting the default parameter until authentication is done
  supplierList = [];
  supplierListResponse: any;
  selectedIndex: number | null;
  appParentId: any;
  fiscalyear: number;
  empId: number;
  selectedSupplierName: any;
  selectedAppParentId: any;
  combinedData = [];
  commodityCodeDetails: any;
  commodityDetails: any;
  prevMBEachievement = '';
  currentMBEachievement = '';
  prevWBEachievement = '';
  currentWBEachievement = '';
  improvementTargetData = [];
  supplierTierIIData: any;
  currentAPRtarget = 0;
  prevAPRtarget = 0;
  minoritytarget = 0;
  wbetarget = 0;
  selectedImprTargetTitle = '';
  selectedImprTargetDesc = '';
  searchSupplier = '';
  totalPacketPercentage = 0;

  constructor(public LookupService: LookupService) {}

  toggleClass() {
    this.isStyled = !this.isStyled;
  }

  applyActiveClass(p, event: Event) {
    if (event.type === 'keyup' || event.type === 'click') {
      if (p === 1) {
        this.tab1 = true;
        this.tab2 = false;
        this.tab3 = false;
        this.tab4 = false;
      } else if (p === 2) {
        this.tab1 = false;
        this.tab2 = true;
        this.tab3 = false;
        this.tab4 = false;
      } else if (p === 3) {
        this.tab1 = false;
        this.tab2 = false;
        this.tab3 = true;
        this.tab4 = false;
      } else if (p === 4) {
        this.tab1 = false;
        this.tab2 = false;
        this.tab3 = false;
        this.tab4 = true;
      }
    }
  }

  rowDataSafetyRisk = [];

  defaultColDef = {
    suppressNavigable: true,
    cellClass: 'border',
    suppressMovable: true,
    sortable: false,
    unSortIcon: true,
    resizable: true,
    minWidth: 60,
  };

  columnDefsSafetyRisk = [
    {
      headerName: 'Supplier Code',
      field: 'suppliercode',
      flex: 1,
    },
    {
      headerName: 'Target',
      field: 'target',
      flex: 1,
    },
    {
      headerName: 'Risk Rating',
      field: 'risk_rating',
      flex: 1,
    },
  ];

  rowDataCommodityDetails = [];

  columDefCommodityDetails = [
    {
      headerName: 'Supplier Code',
      field: 'suppliercode',
      cellRenderer: SupplierCodeRendererComponent,
      flex: 1,
    },
    {
      headerName: 'Commodity',
      field: 'description',
      flex: 4,
    },
    {
      headerName: 'Quality',
      children: [
        {
          headerName: 'OE',
          field: 'oedefecttarget',
          flex: 1,
        },
        {
          headerName: 'Service',
          field: 'servicedefecttarget',
          flex: 1,
        },
        {
          headerName: 'Prototype',
          field: 'prototypedefecttarget',
          flex: 1,
        },
        {
          headerName: 'S-rank QR',
          field: 'srankqpr',
          flex: 1,
        },
      ],
    },
    {
      headerName: 'Delivery KPM',
      field: 'deliverykpm',
      flex: 1,
    },
    {
      headerName: 'Warranty PPM',
      field: 'warrantytarget',
      flex: 1,
    },
  ];

  employeeId:any;
  ngOnInit() {
    sessionStorage.setItem("page","home");
    this.employeeId=sessionStorage.getItem("assumeEmployeeid");
    this.getSupplierlistDetail();
  }

  ngOnDestroy(){
    sessionStorage.removeItem("page");
  }

  ngAfterViewInit() {
    const sourceLabel = document.querySelector('.tlabel1');
    const targetLabel = document.querySelector('.tlabel2');
    const updateHeight = () => {
      if(sourceLabel instanceof HTMLLabelElement && targetLabel instanceof HTMLLabelElement) {
        const sourceHeight = sourceLabel.offsetHeight;
        targetLabel.style.height = `${sourceHeight}px`;
      }
    }
    updateHeight();
    window.addEventListener('resize', updateHeight);
  }

  getSupplierlistDetail() {
    //Assuming identity (Authentication) manually to fetch supplier details
    this.bodyContent = { fiscalYear: 41, employeeId: this.employeeId };

    this.LookupService.getSupplierList(this.bodyContent).subscribe((data) => {
      this.supplierListResponse = data;
      this.supplierList = data.body.supplierList.map(
        (value) => value.description
      );
      this.appParentId = data.body.supplierList.map(
        (value) => value.appparentid
      );

      if (this.supplierListResponse.body.packetApprovalInfo) {
        this.totalPacket =
          this.supplierListResponse.body.packetApprovalInfo[0]
            .activepacketcount ?? 0;
        this.approvedpacket =
          this.supplierListResponse.body.packetApprovalInfo[0]
            .approvedpacketcount ?? 0;
        this.packetNotSent =
          this.supplierListResponse.body.packetApprovalInfo[0]
            .openpacketcount ?? 0;
        this.openPacket =
          this.supplierListResponse.body.packetApprovalInfo[0]
            .notsendingpacketcount ?? 0;
        this.calculatePercentage();
      }

      //Selecting the first supplier detail once logged in//
      this.selectedIndex = 0;
      this.getSelectedSupplierDetails();
    });
  }

  calculatePercentage() {
    this.approvedpacketPercentage = Number(
      ((this.approvedpacket / this.totalPacket) * 100).toFixed(2)
    );
    this.openPacketPercentage = Number(
      ((this.openPacket / this.totalPacket) * 100).toFixed(2)
    );
    this.packetNotSentPercentage = Number(
      ((this.packetNotSent / this.totalPacket) * 100).toFixed(2)
    );
    this.totalPacketPercentage =
      this.approvedpacketPercentage +
      this.openPacketPercentage +
      this.packetNotSentPercentage;
  }

  selectSupplier(index: number, event: Event) {
    //On selecting a supplier from the list we have to fetch all details in the page
    if (event.type === 'keyup' || event.type === 'click') {
      this.selectedIndex = index;
      this.getSelectedSupplierDetails();
    }
  }

  getSelectedSupplierDetails() {
    if (this.supplierList && this.supplierList.length > 0) {
      this.selectedSupplierName = this.supplierList[this.selectedIndex];
      this.selectedAppParentId = this.appParentId[this.selectedIndex];
      this.fiscalyear = this.bodyContent.fiscalYear;
      this.empId = this.bodyContent.employeeId;

      this.LookupService.getSftyRiskCommodity(
        this.selectedAppParentId,
        this.fiscalyear,
        this.empId
      ).subscribe((data) => {
        this.rowDataSafetyRisk = data.body.safetyRisk;
        this.commodityCodeDetails = data.body.commodityCode;
        this.commodityDetails = data.body.commodities;
        this.commodityData();
      });

      this.LookupService.getSupplierTierResult(
        this.selectedAppParentId,
        this.fiscalyear,
        this.empId
      ).subscribe((data) => {
        //improvement target data
        this.improvementTargetData = data.body.imprvtTarget;
        //selecting the first title once logged in//
        this.selectedImprTargetTitle = this.improvementTargetData[0].title;
        this.onSelectingTitle();

        //tierII data
        if (data.body.supplierTier2 && data.body.supplierTier2.length > 0) {
          this.currentAPRtarget =
            data.body.supplierTier2[0].vipmanuimpraprtarget ?? 0;
          this.prevAPRtarget = data.body.supplierTier2[0].previoustarget ?? 0;
          this.minoritytarget =
            data.body.supplierTier2[0].tieriiminoritysourcingtarget ?? 0;
          this.wbetarget = data.body.supplierTier2[0].tieriiwbetarget ?? 0;
        } else {
          this.currentAPRtarget =
            this.prevAPRtarget =
            this.minoritytarget =
            this.wbetarget =
              0;
        }

        //result data
        if (
          data.body.resultPercentage &&
          data.body.resultPercentage.length > 0
        ) {
          this.prevMBEachievement =
            data.body.resultPercentage[0].pastmbeachievement ?? '';
          this.currentMBEachievement =
            data.body.resultPercentage[0].currentmbeachievement ?? '';
          this.prevWBEachievement =
            data.body.resultPercentage[0].pastwbeachievement ?? '';
          this.currentWBEachievement =
            data.body.resultPercentage[0].currentwbeachievement ?? '';
        } else {
          this.prevMBEachievement =
            this.currentMBEachievement =
            this.prevWBEachievement =
            this.currentWBEachievement =
              '';
        }
      });
    }
  }

  commodityData() {
    if (this.commodityCodeDetails && this.commodityCodeDetails.length > 0) {
      this.rowDataCommodityDetails = this.commodityCodeDetails.map(
        (commodity) => {
          //matching values based on appid
          const description =
            this.commodityDetails.find((val) => val.appid === commodity.appid)
              ?.description || '';
          return {
            suppliercode: commodity.suppliercode,
            description: description,
            oedefecttarget: commodity.oedefecttarget,
            servicedefecttarget: commodity.servicedefecttarget,
            prototypedefecttarget: commodity.prototypedefecttarget,
            srankqpr: commodity.srankqpr,
            deliverykpm: commodity.deliverykpm,
            warrantytarget: commodity.warrantytarget,
          };
        }
      );
    }
  }

  onSelectingTitle() {
    const selected = this.improvementTargetData.find(
      (item) => item.title === this.selectedImprTargetTitle
    );
    this.selectedImprTargetDesc = selected ? selected.targetdescription : '';
  }

  get filteredSupplier() {
    return this.supplierList.filter((supplier) =>
      supplier.toLowerCase().includes(this.searchSupplier.toLocaleLowerCase())
    );
  }

  onGridReady(params: any) {
    this.gridApi = params?.api;
    this.gridColumnApi = params?.columnApi;
    this.gridApi?.sizeColumnsToFit();
  }
  @HostListener('window:resize', ['$event'])
  onResize(_event) {
    if (this.gridApi != null) {
      this.gridApi?.sizeColumnsToFit();
      this.gridApi.refreshCells();
    }
  }
}
