import { Component, HostListener, ViewChild } from '@angular/core';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { GridApi } from 'ag-grid-community';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrl: './reports.component.css'
})
export class ReportsComponent {
  @ViewChild('tabGroup') tabGroup: MatTabGroup;
  selectedTabIndex=0;
  paginationPageSize=17;
  domLayout='autoHeight';
  gridApi: GridApi;
  gridApi1!: GridApi;
  gridApi2!: GridApi;
  gridApi3!: GridApi;
  gridApi4!: GridApi;
  gridColumnApi: any;
  //Sonarqube fix items below
  leadBuyerCode = 'LEAD BUYER CODE';
  leadBuyer = 'LEAD BUYER';
  assistantManager = 'ASSISTANT MANAGER'
  onTabChanged(event: MatTabChangeEvent) {
    
  }
  
  defaultColDef={ 
    suppressNavigable: true,
    suppressMovable: true, //Suppressing column drag drop.
    cellClass: 'border',
    // enableRowGroup: true, //To enable ag-grid enterprise Drag, Drop and grouping functionality and in .html file [rowGroupPanelShow]="'always'"
    sortable: true,
    unSortIcon: true,
    floatingFilter: true,
    filter: 'agTextColumnFilter',
    autoHeight: true,
    wrapText: true,
    minWidth: 130
   };

   columnDefSupplierApprovalStatus=[
    {
      headerName: 'SUPPLIER',
      field: 'supplier',
      minWidth: 240,
      flex: 3
    },
    {
      headerName: this.leadBuyerCode,
      field: 'lead_buyer_code',
      flex: 1
    },
    {
      headerName: this.leadBuyer,
      field: 'lead_buyer',
      flex: 1
    },
    {
      headerName: this.assistantManager,
      field: 'assistant_manager',
      flex: 2
    },
    {
      headerName: 'MANAGER',
      field: 'manager',
      flex: 1
    },
    {
      headerName: 'STATUS',
      field: 'status',
      flex: 1
    }
   ];

   rowDataforSupplierApprovalStatus = [
    {'supplier':'3M', 'lead_buyer_code':'05002', 'lead_buyer':'De Armas, Jose', 'assistant_manager':'Tolley, Alexus', 'manager':'Fozkos, Mark', 'status':'Open'},
    {'supplier':'A Raymond Tinnerman AutomotiveIncc.', 'lead_buyer_code':'05040', 'lead_buyer':'Iyer, Katlyn', 'assistant_manager':'sly, robert', 'manager':'Philips, Randy', 'status':'Open'},
    {'supplier':'A.P. Plasman Corporation', 'lead_buyer_code':'05040', 'lead_buyer':'Katlyn, Iyer', 'assistant_manager':'sly, robertt', 'manager':'Philips, Randyy', 'status':'Open'},
    {'supplier':'ABC', 'lead_buyer_code':'05040', 'lead_buyer':'Iyer, Katlynl', 'assistant_manager':'sly, robet', 'manager':'Philips, Randdy', 'status':'Open'},
    {'supplier':'ACSCO Products, Inc.', 'lead_buyer_code':'05040', 'lead_buyer':'Iyer, Katlync', 'assistant_manager':'sly, rob', 'manager':'Philips, Randi', 'status':'Open'},
    {'supplier':'Adient', 'lead_buyer_code':'05040', 'lead_buyer':'Iyer, Katlynv', 'assistant_manager':'sly, robb', 'manager':'Philips, Robbin', 'status':'Open'},
    ]

   columnDefAPRTargets=[
    {
      headerName: 'SUPPLIER',
      field: 'supplier',
      minWidth: 240,
      flex: 2
    },
    {
      headerName: this.leadBuyerCode,
      field: 'lead_buyer_code',
      flex: 1
    },
    {
      headerName: this.leadBuyer,
      field: 'lead_buyer',
      flex: 1
    },
    {
      headerName: this.assistantManager,
      field: 'assistant_manager',
      flex: 1
    },
    {
      headerName: 'MANAGER',
      field: 'manager',
      flex: 1
    },
    {
      headerName: 'CURRENT APR COST REDUCTION TARGET ',
      field: 'current_apr_cost_reduction_target',
      flex: 1
    },
    {
      headerName: 'PREVIOUS APR COST REDUCTION TARGET',
      field: 'previous_apr_cost_reduction_target',
      flex: 1
    }
   ]

   rowDataforAPRTarget = [
     {'supplier':'TTH Automotive Guanajuato S deR.L de C.V', 'lead_buyer_code':'05040', 'lead_buyer':'Iyer, Katly', 'assistant_manager':'sly, derik', 'manager':'Phillippee, Rob', 'current_apr_cost_reduction_target':'2.00%', 'previous_apr_cost_reduction_target':'2.00%'},
    {'supplier':'U.S. Engine Valve Company', 'lead_buyer_code':'05040', 'lead_buyer':'Iyer, Katlynw', 'assistant_manager':'sly, derikk', 'manager':'Phillippe, Rob', 'current_apr_cost_reduction_target':'2.00%', 'previous_apr_cost_reduction_target':'2.00%'},
    {'supplier':'Vuteq', 'lead_buyer_code':'05040', 'lead_buyer':'Iyer, Katlynq', 'assistant_manager':'robert, sly', 'manager':'Phillipe, Rob', 'current_apr_cost_reduction_target':'2.00%', 'previous_apr_cost_reduction_target':'2.00%'},
    {'supplier':'WEASTEC, INC', 'lead_buyer_code':'05040', 'lead_buyer':'Iyer, Katlynw', 'assistant_manager':'robertt, sly', 'manager':'Philipe, Rob', 'current_apr_cost_reduction_target':'2.00%', 'previous_apr_cost_reduction_target':'2.00%'},
    {'supplier':'Yorozu Automotive TN', 'lead_buyer_code':'05040', 'lead_buyer':'Iyer, Katlyna', 'assistant_manager':'robert, slyy', 'manager':'Philipp, Rob', 'current_apr_cost_reduction_target':'2.00%', 'previous_apr_cost_reduction_target':'2.00%'},
    {'supplier':'ZF Active Safety and Electronics US LLC', 'lead_buyer_code':'05040', 'lead_buyer':'Iyer, Katlynz', 'assistant_manager':'robrt, sly', 'manager':'Philipp, Robb', 'current_apr_cost_reduction_target':'2.00%', 'previous_apr_cost_reduction_target':'2.00%'}
   ]

   columnDefTier2Results = [
    {
      headerName: 'SUPPLIER',
      field: 'supplier',
      minWidth: 240,
      flex: 2
    },
    {
      headerName: this.leadBuyerCode,
      field: 'lead_buyer_code',
      flex: 1
    },
    {
      headerName: this.leadBuyer,
      field: 'lead_buyer',
      flex: 1
    },
    {
      headerName: this.assistantManager,
      field: 'assistant_manager',
      flex: 1
    },
    {
      headerName: 'MANAGER',
      field: 'manager',
      flex: 1
    },
    {
      headerName: 'MBE OCT 2025',
      field: 'mbe_oct_2025',
      flex: 1
    },
    {
      headerName: 'MBE APR 2025',
      field: 'mbe_apr_2025',
      flex: 1
    },
    {
      headerName: 'WBE OCT 2025',
      field: 'wbe_oct_2025',
      flex: 1
    },
    {
      headerName: 'WBE APR 2025',
      field: 'wbe_apr_2025',
      flex: 1
    },
    {
      headerName: 'MBE OCT 2024',
      field: 'mbe_oct_2024',
      flex: 1
    },
    {
      headerName: 'MBE APR 2024',
      field: 'mbe_apr_2024',
      flex: 1
    },
    {
      headerName: 'WBE OCT 2024',
      field: 'wbe_oct_2024',
      flex: 1
    },
    {
      headerName: 'WBE APR 2024',
      field: 'wbe_apr_2024',
      flex: 1
    }
   ]

   rowDataforTier2Results = []

   columnDefSupplierIETSummary = [
    {
      headerName: 'SUPPLIER',
      field: 'supplier',
      minWidth: 240,
      flex: 2
    },
    {
      headerName: 'DELIVERY',
      field: 'delivery',
      flex: 1
    },
    {
      headerName: 'EXPORT',
      field: 'export',
      flex: 1
    },
    {
      headerName: 'GENTANI',
      field: 'gentani',
      flex: 1
    },
    {
      headerName: 'COST',
      field: 'cost',
      flex: 1
    },
    {
      headerName: 'HUMAN RESOURCE DEVELOPMENT',
      field: 'human_resource_development',
      flex: 1
    },
    {
      headerName: 'LOCALIZATION',
      field: 'localization',
      flex: 1
    },
    {
      headerName: 'LOGISTICS',
      field: 'logistics',
      flex: 1
    },
    {
      headerName: 'MEXICO',
      field: 'mexico',
      flex: 1
    },
    {
      headerName: 'MONOZUKURI',
      field: 'monozukuri',
      flex: 1,
    },
    {
      headerName: 'DESIGN FOR TECHNOLOGY',
      field: 'design_for_technology',
      flex: 1
    }
   ]

   rowDataforSupplierIETSummary = [
    {'supplier':'A Raymond Tinnerman AutomotiveInc.', 'delivery':'', 'export':'', 'gentani':'', 'cost':'', 'human_resource_development':'', 'localization':'', 'logistics':'', 'mexico':'', 'monozukuri':'', 'design_for_technology':''},
    {'supplier':'NIE', 'delivery':'', 'export':'', 'gentani':'', 'cost':'', 'human_resource_development':'', 'localization':'', 'logistics':'', 'mexico':'', 'monozukuri':'', 'design_for_technology':''},
    {'supplier':'ABC', 'delivery':'X', 'export':'', 'gentani':'X', 'cost':'X', 'human_resource_development':'', 'localization':'', 'logistics':'', 'mexico':'', 'monozukuri':'', 'design_for_technology':''}
   ]

  onGridReady(params:any){
    this.gridApi = params?.api;
    this.gridColumnApi = params?.columnApi;
    this.gridApi?.sizeColumnsToFit(); 
  }

  @HostListener("window:resize", ['$event'])
  onResize(_event) {
    if (this.gridApi != null) {
      this.gridApi?.sizeColumnsToFit();
      this.gridApi.refreshCells();
    }
  }

  exportDataToExcel(jsonData: any[], columnHeaderData: any[], fileName: string): void {

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);

    const headerNames = columnHeaderData.map(col => col.headerName);
    const headerRow = {};
    const colWidth = headerNames.map((header, index)=> {
      const dataLength = Math.max(
        ...jsonData.map(row => String(row[columnHeaderData[index].field] || '').length),
        header.length
      );
      return dataLength + 2;
    });

    headerNames.forEach((header, index) => {
      headerRow[String.fromCharCode(65 + index) + '1'] = header;
    });

    const finalWorkSheet = XLSX.utils.sheet_add_json(worksheet, [headerRow], { skipHeader: true, origin: 'A1' });
    finalWorkSheet['!cols'] = colWidth.map(width => ({ wch: width }));

    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, finalWorkSheet);

    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  }

}
