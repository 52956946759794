import { NgModule, ErrorHandler} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { LoggerService } from './logger.service';
import { CustomErrorHandler } from './errorhandler.provider';
import { AppRoutingModule } from './app-routing.module';
import {MatTabsModule} from '@angular/material/tabs';
import { AgGridModule } from 'ag-grid-angular';


/**Component used in the APP */
import { AdminComponent } from './admin/admin.component';
import { HomeComponent } from './home/home.component';
import { PacketSetComponent } from './packet-set/packet-set.component';
import { ReportsComponent } from './reports/reports.component';

import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { NavigationBarComponent } from './core/navigation-bar/navigation-bar.component';
import { FooterComponent } from './core/footer/footer.component';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { IseComponent } from './ise/ise.component';
import { BreadcrumbComponent } from './core/breadcrumb/breadcrumb.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IseSupplierCodeRendererComponent } from './ise/ise-supplier-code-renderer/ise-supplier-code-renderer.component';
import { SupplierDetailsComponent } from './supplier-details/supplier-details.component';
import { IseIetTitleRendererComponent } from './ise/ise-iet-title-renderer/ise-iet-title-renderer.component';
import { IetComponent } from './ise/iet/iet.component';
import { provideHttpClient } from '@angular/common/http';
import { SupplierCodeRendererComponent } from './home/supplier-code-renderer/supplier-code-renderer.component';
import { EmailerComponent } from './admin/emailer/emailer.component';





ModuleRegistry.registerModules([ClientSideRowModelModule]);
@NgModule({
  declarations: [
    AppComponent,NavigationBarComponent,FooterComponent,AdminComponent,
    HomeComponent,PacketSetComponent,ReportsComponent,IseComponent,BreadcrumbComponent,
    IseSupplierCodeRendererComponent, SupplierDetailsComponent, IseIetTitleRendererComponent, IetComponent,
    SupplierCodeRendererComponent, EmailerComponent
  ],

  imports: [
   MatTabsModule, AgGridModule,BrowserModule,AppRoutingModule,BrowserAnimationsModule,
   MatCardModule,MatNativeDateModule,MatDatepickerModule,MatToolbarModule,MatIconModule,NgxSpinnerModule,FormsModule,ReactiveFormsModule
  ],

  providers: [LoggerService, { provide: ErrorHandler, useClass: CustomErrorHandler }, provideHttpClient()],
  bootstrap: [AppComponent]
})
export class AppModule { }
