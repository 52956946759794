import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-improvement-target-edit',
  templateUrl: './improvement-target-edit.component.html',
  styleUrl: './improvement-target-edit.component.css'
})
export class ImprovementTargetEditComponent {
  refresh(_params: ICellRendererParams): boolean {
    throw new Error('Method not implemented.');
  }

}
