<div fxFlex="25" class="app-breadcrumb">
    <mat-toolbar>
        <span *ngFor="let m of menuHeader; let indx = index" >
            <div class="menu-item" [class.active]="m.link===null">
                <a mat-button style="min-width:0px;" (click)="breadCrumb(menuHeader, indx)" >
                    {{m.label}}
                </a>
                <mat-icon mat-button fxFlex="10">{{m.icon}}</mat-icon>
            </div>
        </span>
    </mat-toolbar>
</div>
