import { Injectable } from '@angular/core';
import { datadogLogs } from '@datadog/browser-logs'


@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  constructor() {
    // See the README.md to learn more on getting started with DataDog browser-logs.
    datadogLogs.init({
      clientToken: '<DATADOG_CLIENT_TOKEN>',
      site: '<DATADOG_SITE>',
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
    })
    
  }

  log(message: string): void {
    const logMessage = `[INFO] ${message}`;
    // Simple Console Log to Demo INFO Level
    console.log(logMessage);
    // DataDog info message. Message won't be sent until valid client 
    // token and site are provided to init method.
    datadogLogs.logger.info(logMessage);

    // If you have a backend API to accept log messages from the client,
    // you can do the following:
    // this.http.post('/angular/log', {
    //   message: logMessage
    // }).subscribe((res) => console.log(res));
    // More details in the README.md.
  }

  warn(message: string): void {
    const logMessage = `[WARNING] ${message}`;
    // Simple Console Log to Demo WARN Level
    console.warn(logMessage);
    // DataDog warn message. Message won't be sent until valid client 
    // token and site are provided to init method.
    datadogLogs.logger.warn(message);

    // If you have a backend API to accept log messages from the client,
    // you can do the following:
    // this.http.post('/angular/log', {
    //   message: logMessage
    // }).subscribe((res) => console.log(res));
    // More details in the README.md.
  }

  error(message: string): void {
    const logMessage = `[ERROR] ${message}`;
    // Simple Console Log to Demo ERROR Level
    console.error(logMessage);
    // DataDog error message. Message won't be sent until valid client 
    // token and site are provided to init method.
    datadogLogs.logger.error(logMessage);

    // If you have a backend API to accept log messages from the client,
    // you can do the following:
    // this.http.post('/angular/log', {
    //   message: logMessage
    // }).subscribe((res) => console.log(res));
    // More details in the README.md.
  }
}
