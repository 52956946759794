import { Component } from '@angular/core';

@Component({
  selector: 'app-packet-set',
  templateUrl: './packet-set.component.html',
  styleUrl: './packet-set.component.css'
})
export class PacketSetComponent {

}
