import { Component, HostListener } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { GridApi } from 'ag-grid-community';
import { IseSupplierCodeRendererComponent } from './ise-supplier-code-renderer/ise-supplier-code-renderer.component';
import { IseIetTitleRendererComponent } from './ise-iet-title-renderer/ise-iet-title-renderer.component';

@Component({
  selector: 'app-ise',
  templateUrl: './ise.component.html',
  styleUrl: './ise.component.css'
})
export class IseComponent {
  isOpen1=true;
  isOpen2=true;
  gridApi:GridApi;
  gridColumnApi:any;
  domLayout='autoHeight';
  paginationPageSize=4;
  leadBuyerEmail='joe@toyota.com';
  selectedTabIndex=0;
  breadcrumbItems=[{
    label: 'Home',
    link: '/home',
    icon: '>'
  },
  {
    label: 'View/edit ISE',
    link: null,
    icon: ''
  },];

  defaultColDef={ 
    suppressNavigable: true,
    cellClass: 'border',
    suppressMovable: true,
    sortable: false,
    unSortIcon: true,
    wrapText: true,
    autoHeight: true
   };

   columnDefSupplierContacts=[
    {
      headerName: 'Supplier Division',
      field: 'supplier_division',
      flex: 1
    },
    {
      headerName: 'WARP Contact Type',
      field: 'warp_contact_type',
      flex: 1
    },
    {
      headerName: 'Name',
      field: 'name',
      flex: 1
    },
    {
      headerName: 'Title',
      field: 'title',
      flex: 1
    },
    {
      headerName: 'Email',
      field: 'email',
      flex: 1
    }
  ]

   rowDataSupplierContacts=[
    {'supplier_division': '25001', 'warp_contact_type': 'Sales', 'name': 'John Fraser', 'title': 'Sales Rep', 'email': 'jrfraser@mmm.com'},
    {'supplier_division': '25002', 'warp_contact_type': 'Sales', 'name': 'John Fraser', 'title': 'Sales Rep', 'email': 'jrfraser@mmm.com'}
  ]

   columnDefSuppliers = [
    {
      headerName: 'Supplier Code',
      field: 'supplier_code',
      cellRenderer: IseSupplierCodeRendererComponent,
      flex: 1
    },
    {
      headerName: 'Supplier Name',
      field: 'supplier_name',
      flex: 2
    }
  ]

   rowDataSuppliers = [
    {'supplier_code': '25001', 'supplier_name': '3M Company1'},
    {'supplier_code': '25002', 'supplier_name': '3M Company2'},
    {'supplier_code': '25003', 'supplier_name': '3M Company3'},
    {'supplier_code': '25004', 'supplier_name': '3M Company4'},
    {'supplier_code': '25005', 'supplier_name': '3M Company5'},
    {'supplier_code': '25006', 'supplier_name': '3M Company6'},
    {'supplier_code': '25007', 'supplier_name': '3M Company7'},
    {'supplier_code': '25008', 'supplier_name': '3M Company8'},
    {'supplier_code': '25009', 'supplier_name': '3M Company9'},
    {'supplier_code': '25010', 'supplier_name': '3M Company0'}
  ]

  columnDefIndividualExpectedTasks=[
    {
    headerName: 'Title',
    field: 'title',
    cellRenderer: IseIetTitleRendererComponent,
    minWidth: 120,
    flex: 2
    },
    {
      headerName: 'Body',
      field: 'body',
      minWidth: 400,
      flex: 5
    },
    {
      headerName: 'Date Entered',
      field: 'date',
      minWidth: 100,
      flex: 1
    },
    {
      headerName: 'Status',
      field: 'status',
      minWidth: 60,
      flex: 1
    }
  ]

  rowDataIndividualExpectedTasks=[
  {'title':'Supply Chain Management1','body':'Sed1 ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas','date':'10/3/2024 12:45 pm','status':'open'},
  {'title':'Supply Chain Management2','body':'Sed2 ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas','date':'10/3/2024 12:46 pm','status':'open'},
  {'title':'Supply Chain Management3','body':'Sed3 ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas','date':'10/3/2024 12:47 pm','status':'open'},
  {'title':'Supply Chain Management4','body':'Sed4 ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas','date':'10/3/2024 12:48 pm','status':'open'}
  ]

   onGridReady(params:any){
    this.gridApi = params?.api;
    this.gridColumnApi = params?.columnApi;
    this.gridApi?.sizeColumnsToFit(); 
  }
  @HostListener("window:resize", ['$event'])
  onResize(_event) {

    if (this.gridApi != null) {

      this.gridApi?.sizeColumnsToFit();
      this.gridApi.refreshCells();
    }

  }
  onTabChanged(event: MatTabChangeEvent) {
    
  }
}
