import { Component, HostListener, OnInit, ViewChild, model } from '@angular/core';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { ImprovementTargetEditComponent } from './improvement-target-edit/improvement-target-edit.component';
import { GridApi, GridOptions } from 'ag-grid-community';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { LookupService } from '../services/lookup.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrl: './admin.component.css'
})
export class AdminComponent implements OnInit {
  @ViewChild('tabGroup') tabGroup: MatTabGroup;
  isOpen=true;
  showTab='first';
  selectedValue=false;
  selectedTabIndex=0;
  paginationPageSize=4;
  domLayout='autoHeight';
  selected = model<Date | null>(null);
  gridApi: GridApi;
  gridOptions: GridOptions;
  gridColumnApi: any;
  adminForm:FormGroup;
  
  onTabChanged(event: MatTabChangeEvent) {
   
  }
  constructor(private readonly ngxSpinner:NgxSpinnerService,private readonly formBuilder:FormBuilder
    ,private readonly lookup:LookupService
    ){}
 ngOnInit():void{
  this.showISE();
  this.adminForm=this.formBuilder.group({
    iseTabNodeValue:new FormControl(''),
    isetabNodeExplanation:new FormControl(''),
    iseTabSubNodeValue:new FormControl(''),

  })
 }

 supplierList:any=[];
  rowDataForImprovementTarget=[{'title':'Japan Affiliated Supplier','target_description':`Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur? Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur`,'display_order':'2','inactive':'true'},
  {'title':'Japan Affiliated Supplier','target_description':`Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur? Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur`,'display_order':'2','inactive':'true'},
  {'title':'Japan Affiliated Supplier','target_description':`Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur? Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur`,'display_order':'2','inactive':'true'},];
  defaultColDef={ suppressNavigable: true,
    cellClass: 'no-border',
    suppressMovable: true,
    sortable: false,
    unSortIcon: true,
    floatingFilter: true,
    filter: 'agTextColumnFilter',
    wrapText: true,
    minWidth: 130,
   };
   columnDefForImprovementTarget=[{
    headerName: 'SL.no',
    field: '',
    cellRenderer: ImprovementTargetEditComponent,
    minWidth: 40,
    },
    {
      headerName: 'Title',
      field: 'title',
      minWidth: 40,
    },
    {
      headerName: 'Target Description',
      field: 'target_description',
      minWidth: 400,
    },
    {
      headerName: 'Display Order',
      field: 'display_order',
      minWidth: 40,
    },
    {
      headerName: 'Inactive ',
      field: 'inactive',
      minWidth: 40,
    }
   ];

  descrip: string;
  apparentid: any;
  inactive: any;
  fiscalYear:any=41;
  onItemSelection(descrip: string,apparentid: any,inactive: any) {
    this.descrip = descrip;
    this.apparentid=apparentid;
    if(inactive===true){
      this.inactive=false;
    }
    else if(inactive===false){
      this.inactive=true;
    }
  }
  showISE(){
    this.lookup.getISESuppliers(this.fiscalYear).subscribe(data => {
      this.supplierList = data.body.response;
      this.descrip = '';
      this.apparentid = '';
      this.inactive = '';
    })
  }
  toggle(){
    const togglebody={
      "parentId": this.apparentid,
      "inactive": this.inactive,
      "modifiedBy": "Kumar Satyam"
    }
    this.lookup.toggleISESuppliers(togglebody).subscribe(_data=>{
      this.showISE();
    })
  }
   onGridReady(params:any){
    this.gridApi = params?.api;
    this.gridColumnApi = params?.columnApi;
    this.gridApi?.sizeColumnsToFit(); 
  }
  @HostListener("window:resize", ['$event'])
  onResize(_event) {

    if (this.gridApi != null) {

      this.gridApi?.sizeColumnsToFit();
      this.gridApi.refreshCells();
    }

  }

  standardIETsAccordianValue=[{id:0,title:'Capacity/Valume Planning',titleExplanation:'Explain the title ',content:['Here is the content 1'],toggle:false},
  {id:1,title:'Capacity/Valume Planning',titleExplanation:'Explain the title ',content:['Here is the content 1'],toggle:false},
  {id:2,title:'Delhivery',titleExplanation:'Explain the title ',content:['Here is the content one'],toggle:false},
  {id:3,title:'Global best cost achievement',titleExplanation:'Explain the title ',content:['Here is the content 2'],toggle:false},
  {id:4,title:'Human resource ',titleExplanation:'Explain the title ',content:['Here is the content 3'],toggle:false},
  {id:5,title:'Mozukariss',titleExplanation:'Explain the title ',content:['Here is the content 4'],toggle:false},
  {id:6,title:'Product preparation',titleExplanation:'Explain the title ',content:['Here is the content 5'],toggle:false},
  {id:7,title:'IT DEpartment',titleExplanation:'Explain the title ',content:['Here is the content 6'],toggle:false}];

  selectedSubnode(value,block){
     this.showTab=block
    console.log(value,block);
    // this.adminForm.controls['iseTabNodeValue'].setValue(value.title);
    // this.adminForm.controls['isetabNodeExplanation'].setValue(value.titleExplanation);
    // console.log(this.adminForm.controls['iseTabNodeValue'])

  

  }

}
